<template>
  <div class="container mx-auto pt-5 pb-[70px] px-4 sm:px-0">
    <div class="grid sm:grid-cols-2">
      <div class="col">
        <div class="relative">
          <div
            class="text-[32px] sm:text-[40px] font-extrabold text-black-agro"
          >
            AGROFARM PRODUCT
          </div>
          <div
            class="hidden sm:block absolute top-[5px] left-[5px] text-[40px] font-extrabold text-black-agro opacity-10"
          >
            AGROFARM PRODUCT
          </div>
        </div>
        <div class="mt-3 text-base sm:text-lg leading-8 text-gray-agro">
          There is our featured product in different categories to increase more
          growth.
        </div>
        <router-link class="hidden sm:block" to="/product">
          <v-btn
            class="mt-7 btn-header rounded-full"
            variant="outlined"
            append-icon="mdi-arrow-top-right"
          >
            See More
          </v-btn>
        </router-link>
      </div>
      <div class="col">
        <carousel
          class="block sm:hidden"
          :items-to-show="1"
          :snapAlign="'start'"
          wrapAround
        >
          <slide v-for="(data, key) in listProduct" :key="key">
            <card-product-landing
              :id="data.id"
              :image="data.attributes.photos[0]"
              :category="data.attributes.category"
              :name="data.attributes.name"
              :description="data.attributes.description"
            />
          </slide>
          <template #addons>
            <navigation />
          </template>
        </carousel>
        <carousel
          class="hidden sm:block"
          :items-to-show="2"
          :snapAlign="'start'"
          wrapAround
        >
          <slide v-for="(data, key) in listProduct" :key="key">
            <card-product-landing
              :id="data.id"
              :image="data.attributes.photos[0]"
              :category="data.attributes.category"
              :name="data.attributes.name"
              :description="data.attributes.description"
            />
          </slide>
          <template #addons>
            <navigation />
          </template>
        </carousel>
      </div>
      <router-link class="block sm:hidden m-auto" to="/product">
        <v-btn
          class="mt-7 btn-header rounded-full"
          variant="outlined"
          append-icon="mdi-arrow-top-right"
        >
          See More
        </v-btn>
      </router-link>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
import { Carousel, Slide, Navigation } from "vue3-carousel";
import CardProductLanding from "@/components/Products/CardProductLanding.vue";

export default {
  name: "ListProductLanding",
  components: {
    Carousel,
    Slide,
    Navigation,
    CardProductLanding,
  },
  setup() {
    const store = useStore();

    const listProduct = computed(() => {
      return store.getters.getListProduct;
    });

    return { listProduct };
  },
};
</script>