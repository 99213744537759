<template>
  <router-link :to="`/product/${id}`">
    <div class="flex flex-col sm:flex-row items-center">
      <img
        alt="agro-product"
        class="m-auto sm:m-0 sm:col-span-2 z-10 p-2 w-[206.25px] h-[275px] object-cover"
        :src="image"
      />
      <div
        class="relative text-left sm:left-[-45px] right-0 sm:pl-[30px] p-5 mx-[40px] sm:mx-0 bg-grey-agro rounded-[20px] z-0 border border-white-agro"
      >
        <div
          class="text-xs font-semibold rounded-full text-green-agro border-green-agro-2 bg-green-agro-1 inline-block px-3 py-1"
        >
          {{ category }}
        </div>
        <div class="mt-2 text-lg font-bold leading-9">{{ name }}</div>
        <div
          :class="cardProductLandingModule.text_description"
          v-html="limitString(description || '', 35)"
        />
      </div>
    </div>
    <!-- <div class="grid sm:grid-cols-5 items-center">
      <img
        alt="agro-product"
        class="m-auto sm:m-0 sm:col-span-2 z-10 p-2 w-[210px] h-[276px] object-cover"
        :src="image"
      />
      <div
        class="relative sm:col-span-3 text-left sm:left-[-25px] right-0 sm:pl-[30px] p-5 bg-grey-agro rounded-[20px] z-0"
      >
        <div
          class="text-xs font-semibold rounded-full text-green-agro border-green-agro-2 bg-green-agro-1 inline-block px-3 py-1"
        >
          {{ category }}
        </div>
        <div class="mt-2 text-lg font-bold leading-9">{{ name }}</div>
        <div
          :class="cardProductLandingModule.text_description"
          v-html="limitString(description || '', 40)"
        />
      </div>
    </div> -->
  </router-link>
</template>

<script>
import formats from "@/modules/formats.js";
import cardProductLandingModule from "./CardProductLanding.module.css";

export default {
  name: "CardProductLanding",
  props: {
    id: {
      type: Number,
    },
    image: {
      type: String,
    },
    category: {
      type: String,
    },
    name: {
      type: String,
    },
    description: {
      type: String,
    },
  },
  setup() {
    const { limitString } = formats();

    return { limitString, cardProductLandingModule };
  },
};
</script>