import axios from 'axios';

export default {
    state: {
        about: []
    },
    getters: {
        getAbout: (state) => {
            return state.about;
        },
    },
    actions: {
        async getAbout({ commit }) {
            const url =
                process.env.VUE_APP_NEW_API_URL +
                `/abouts/1`;
            try {
                const response = await axios({
                    method: 'get',
                    url: url,
                });

                if (response.status === 200) {
                    commit('setAbout', response.data.data);
                }

                return response;
            } catch (error) {
                return error.response;
            }
        },
    },
    mutations: {
        setAbout: (state, data) => {
            state.about = data;
        },
    },
};
