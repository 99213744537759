<template>
  <router-link :to="`/article/${id}`">
    <div class="grid grid-cols-3 gap-4 p-3 rounded bg-white shadow">
      <img
        alt="agro-product"
        class="hidden sm:block col rounded-l h-[170px] w-full object-cover"
        :src="image"
      />
      <div class="col-span-3 sm:col-span-2">
        <div class="bg-ascent-500 px-3 rounded text-[11px] w-fit">
          {{ category }}
        </div>
        <div class="text-lg sm:text-2xl font-bold text-left">
          {{ limitString(title || "", 50) }}
        </div>
        <div
          class="text-sm font-normal text-left mt-1 sm:mt-3"
          v-html="limitString(description || '', 150)"
        />
      </div>
    </div>
  </router-link>
</template>

<script>
import formats from "@/modules/formats.js";
export default {
  name: "CardResource",
  props: {
    id: {
      type: String,
    },
    image: {
      type: String,
    },
    category: {
      type: String,
    },
    title: {
      type: String,
    },
    description: {
      type: String,
    },
  },
  setup() {
    const { limitString } = formats();

    return { limitString };
  },
};
</script>