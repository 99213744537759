import axios from 'axios';

export default {
	state: {
		listTestimony: [],
		pageTestimony: 1,
		sizeTestimony: 10,
	},
	getters: {
		getListTestimony: (state) => {
			return state.listTestimony;
		},
		getPageTestimony: (state) => {
			return state.pageTestimony;
		},
		getSizeTestimony: (state) => {
			return state.sizeTestimony;
		},
	},
	actions: {
		async getListTestimony({ commit }) {
			const params = new URLSearchParams({
				'pagination[page]': 1,
				'pagination[pageSize]': 100,
			});
			const url =
				process.env.VUE_APP_NEW_API_URL +
				`/tesimonies?` +
				params.toString();
			try {
				const response = await axios({
					method: 'get',
					url: url,
				});

				if (response.status === 200) {
					commit('setListTestimony', response.data.data);
				}

				return response;
			} catch (error) {
				return error.response;
			}
		},
	},
	mutations: {
		setListTestimony: (state, data) => {
			state.listTestimony = data;
		},
		setPageTestimony: (state, data) => {
			state.pageTestimony = data;
		},
		setSizeTestimony: (state, data) => {
			state.sizeTestimony = data;
		},
	},
};
