import axios from 'axios';

export default {
	state: {
		listProduct: [],
		pageInfoProduct: [],
		pageProduct: 1,
		sizeProduct: 10,
		categoryProduct: '',
		detailProduct: [],
		brosur: '',
	},
	getters: {
		getListProduct: (state) => {
			return state.listProduct;
		},
		getPageInfoProduct: (state) => {
			return state.pageInfoProduct;
		},
		getPageProduct: (state) => {
			return state.pageProduct;
		},
		getSizeProduct: (state) => {
			return state.sizeProduct;
		},
		getCategoryProduct: (state) => {
			return state.categoryProduct;
		},
		getDetailProduct: (state) => {
			return state.detailProduct;
		},
		getBrosur: (state) => {
			return state.brosur;
		},
	},
	actions: {
		async getListProduct({ state, commit }) {
			const params = new URLSearchParams({
				'pagination[page]': state.pageProduct,
				'pagination[pageSize]': state.sizeProduct,
			});

			if (
				state.categoryProduct !== undefined &&
				state.categoryProduct !== null &&
				state.categoryProduct !== ''
			) {
				params.append('filters[category]', state.categoryProduct);
			}

			if (
				state.titleProduct !== undefined &&
				state.titleProduct !== null &&
				state.titleProduct !== ''
			) {
				params.append('filters[title]', state.titleProduct);
			}

			const url =
				process.env.VUE_APP_NEW_API_URL +
				`/products?` +
				params.toString();
			try {
				const response = await axios({
					method: 'get',
					url: url,
				});
				if (response.status === 200) {
					const res = response.data.data.map((item) => {
						return {
							...item,
							attributes: {
								...item.attributes,
								photos: item.attributes.photos.split('|'),
								type: item.attributes.length,
							},
						};
					});
					commit('setListProduct', res);
					commit('setPageInfoProduct', response.data.meta.pagination);
				}
				return response;
			} catch (error) {
				return error.response;
			}
		},
		async getDetailProduct({ commit }, data) {
			const url =
				process.env.VUE_APP_NEW_API_URL +
				`/products/${data}?populate=variants`;
			try {
				const response = await axios({
					method: 'get',
					url: url,
				});

				var apiData = response.data.data;

				// Check if 'photos' property exists for a single object
				if (apiData.attributes && apiData.attributes.photos) {
					// Split 'photos' string by "|" to create an array
					const photosArray = apiData.attributes.photos.split('|');

					// Split 'plant' string by "|" to create an array
					const plantArray = apiData.attributes.plant.split('|');

					// Split 'type' string by "|" to create an array
					const typeArray = apiData.attributes.type.split('|');

					apiData.attributes.photos = photosArray;
					apiData.attributes.plant = plantArray;
					apiData.attributes.type = typeArray;
				}

				commit('setDetailProduct', apiData);

				return response;
			} catch (error) {
				return error.response;
			}
		},
		async addListProduct(_, data) {
			const url = process.env.VUE_APP_API_URL + `/product`;
			try {
				const response = await axios({
					method: 'post',
					url: url,
					data,
					headers: {
						Authorization: `
								  ${JSON.parse(window.localStorage.getItem('agrofarm'))?.token}`,
					},
				});
				return response;
			} catch (error) {
				return error.response;
			}
		},
		async editListProduct(_, data) {
			const url = process.env.VUE_APP_API_URL + `/product`;
			try {
				const response = await axios({
					method: 'put',
					url: url,
					data,
					headers: {
						Authorization: `
								  ${JSON.parse(window.localStorage.getItem('agrofarm'))?.token}`,
					},
				});
				return response;
			} catch (error) {
				return error.response;
			}
		},
		async deleteListProduct(_, data) {
			const url = process.env.VUE_APP_API_URL + `/product/${data}`;
			try {
				const response = await axios({
					method: 'delete',
					url: url,
					data,
					headers: {
						Authorization: `
								  ${JSON.parse(window.localStorage.getItem('agrofarm'))?.token}`,
					},
				});
				return response;
			} catch (error) {
				return error.response;
			}
		},
		async getBrosur({ commit }) {
			const url = process.env.VUE_APP_NEW_API_URL + `/url-display`;
			try {
				const response = await axios({
					method: 'get',
					url: url,
				});
				commit('setBrosur', response.data.data.attributes.url);
				return response;
			} catch (error) {
				return error.response;
			}
		},
	},
	mutations: {
		setListProduct: (state, data) => {
			state.listProduct = data;
		},
		setPageInfoProduct: (state, data) => {
			state.pageInfoProduct = data;
		},
		setPageProduct: (state, data) => {
			state.pageProduct = data;
		},
		setSizeProduct: (state, data) => {
			state.sizeProduct = data;
		},
		setCategoryProduct: (state, data) => {
			state.categoryProduct = data;
		},
		setDetailProduct: (state, data) => {
			state.detailProduct = data;
		},
		setBrosur: (state, data) => {
			state.brosur = data;
		},
	},
};
