<template>
    <ul :class="styles.breadcrumb">
        <li v-for="(item, index) in items" :key="index">
            <a :href="item.href">{{ item.title }}</a>
            <span v-if="index < items.length - 1"> / </span>
        </li>
    </ul>
</template>

<script>
import styles from "./BreadcrumbUi.module.css";

export default {
    name: "BreadcrumbUi",
    props: {
        items: {
            type: Array,
            required: true
        }
    },
    setup() {
        return {
            styles
        };
    }
};
</script>
