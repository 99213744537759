import axios from 'axios';

export default {
	state: {
		listArticle: [],
		pageArticle: 1,
		sizeArticle: 10,
		filterArticle: '',
		categoryArticle: '',
		titleArticle: '',
		detailArticle: [],
	},
	getters: {
		getListArticle: (state) => {
			return state.listArticle;
		},
		getPageArticle: (state) => {
			return state.pageArticle;
		},
		getSizeArticle: (state) => {
			return state.sizeArticle;
		},
		getFilterArticle: (state) => {
			return state.filterArticle;
		},
		getCategoryArticle: (state) => {
			return state.categoryArticle;
		},
		getTitleArticle: (state) => {
			return state.titleArticle;
		},
		getDetailArticle: (state) => {
			return state.detailArticle;
		},
	},
	actions: {
		async getListArticle({ state, commit }) {
			var page = state.pageArticle !== null ? state.pageArticle : 1;
			var pageSize = state.sizeArticle !== null ? state.sizeArticle : 10;

			const params = new URLSearchParams({
				'pagination[page]': page,
				'pagination[pageSize]': pageSize,
				'filters[category]': state.filterArticle
			});

			if (state.categoryArticle) {
				params.append('filters[category][$startsWith]', state.categoryArticle);
			}

			if (state.titleArticle) {
				params.append('filters[title][$startsWith]', state.titleArticle);
			}

			const url =
				process.env.VUE_APP_NEW_API_URL +
				`/articles?` +
				params.toString();
			try {
				const response = await axios({
					method: 'get',
					url: url,
				});

				commit('setListArticle', response.data.data);
				return response;
			} catch (error) {
				return error.response;
			}
		},
		async getDetailArticle({ commit }, data) {
			const url = process.env.VUE_APP_NEW_API_URL + `/articles/${data}`;
			try {
				const response = await axios({
					method: 'get',
					url: url,
				});

				commit('setDetailArticle', response.data.data);
				return response;
			} catch (error) {
				return error.response;
			}
		},
	},
	mutations: {
		setListArticle: (state, data) => {
			state.listArticle = data;
		},
		setPageArticle: (state, data) => {
			state.pageArticle = data;
		},
		setSizeArticle: (state, data) => {
			state.sizeArticle = data;
		},
		setFilterArticle: (state, data) => {
			state.filterArticle = data;
		},
		setCategoryArticle: (state, data) => {
			state.categoryArticle = data;
		},
		setTitleArticle: (state, data) => {
			state.titleArticle = data;
		},
		setDetailArticle: (state, data) => {
			state.detailArticle = data;
		},
	},
};
