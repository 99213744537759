<template>
    <section :class="styles.about_landing__section">
        <div :class="styles.about_landing__inner">
            <div :class="cn(styles.about_landing__text, styles[page])">
                <h2 :class="cn(styles.about_landing__title, styles[page])">
                    Membangun Industri Pangan yang Kuat Untuk Indonesia
                </h2>

                <a v-if="!disableButton" href="/about"
                    :class="cn(styles.about_landing__button, styles.about_landing__button_desktop)">
                    <p>Tentang Kami</p>
                    <div :class="styles.about_landing__button_arrow" />
                </a>
            </div>

            <div :class="styles.about_landing__points">
                <h2 :class="cn(styles.about_landing__points_title, styles[page])">
                    Membangun Industri Pangan yang Kuat Untuk Indonesia
                </h2>

                <div :class="styles.about_landing__points_wrapper">
                    <div :class="styles.about_landing__points_item">
                        <div :class="styles.icons__box">
                            <div :class="styles.icon"></div>
                        </div>

                        <div :class="styles.title">Riset dan Inovasi</div>
                        <p>Dengan inovasi terus-menerus dan riset berkelanjutan, kami berupaya berkontribusi signifikan
                            terhadap ketahanan pangan dan meningkatkan kesejahteraan petani di Indonesia dalam pertanian dan
                            perkebunan</p>
                    </div>

                    <div :class="styles.about_landing__points_item">
                        <div :class="styles.icons__box">
                            <div :class="styles.icon"></div>
                        </div>

                        <div :class="styles.title">Solusi akan Pupuk</div>
                        <p>Berkomitmen untuk Menjamin Ketahanan Pangan di Indonesia. Kami menyediakan pupuk & nutrisi
                            tanaman yang berkelanjutan dari solusi pertanian-perkebunan terpadu bagi para petani</p>
                    </div>

                    <div :class="styles.about_landing__points_item">
                        <div :class="styles.icons__box">
                            <div :class="styles.icon"></div>
                        </div>

                        <div :class="styles.title">Solusi Agri</div>
                        <p>Kami berkomitmen mendampingi petani untuk pertanian berkelanjutan dengan memilih pupuk, melakukan
                            perawatan, aplikasi nutrisi, dan distribusi efektif untuk memberikan dampak positif yang pasti
                            bagi petani</p>
                    </div>

                    <div :class="styles.about_landing__points_item">
                        <div :class="styles.icons__box">
                            <div :class="styles.icon"></div>
                        </div>

                        <div :class="styles.title">Komitmen Keberlanjutan</div>
                        <p>Jadilah bagian dari inovasi yang mengembangkan bisnis kami, serta membantu mempertahankan kehidupan yang lebih baik bagi pelanggan, keluarga, masyarakat dan lingkungan</p>
                    </div>
                </div>
            </div>


            <div v-if="!disableButton" class="relative flex-1 flex justify-center mt-8 xl:hidden">
                <a href="/about" :class="cn(styles.about_landing__button, styles.about_landing__button_mobile)">
                    <p>Tentang Kami</p>
                    <div :class="styles.about_landing__button_arrow" />
                </a>
            </div>
        </div>
    </section>
</template>

<script>
import styles from "./AboutLanding.module.css";
import classNames from 'classnames';
import { useRouter } from 'vue-router';

export default {
    name: "AboutLanding",
    props: {
        disableButton: {
            type: Boolean,
            default: false
        },        
    },
    setup() {
        const route = useRouter();
        const page = route.currentRoute.value.name;

        return {
            styles,
            cn: classNames,
            page
        };
    }
};
</script>
