import { createStore } from 'vuex';
import authModule from './authModule';
import articleModules from './articleModules';
import productModules from './productModules';
import testimonyModules from './testimonyModules';
import careerModules from './careerModules';
import aboutModules from './aboutModules';
import sustainbilityModules from './sustainbilityModules';

export default createStore({
	state: {},
	getters: {},
	mutations: {},
	actions: {},
	modules: {
		authModule,
		articleModules,
		productModules,
		testimonyModules,
		careerModules,
		aboutModules,
		sustainbilityModules
	},
});
