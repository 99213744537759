<template>
  <div :class="styles.about_view">
    <div :class="styles.about_view__inner">
      <!-- GUIDE MOBILE -->
      <div :class="styles.about_view__guide">
        <a
          :key="index"
          v-for="(guide, index) in listGuide"
          :class="[
            styles.about_view__guide_item,
            activeGuide === index ? styles.guide_active : '',
          ]"
          @click="setActiveGuide(index)"
          :href="guide.link"
        >
          {{ guide.name }}
        </a>
      </div>
      <!-- GUIDE MOBILE -->

      <div :class="styles.about_view__about">
        <div :class="styles.about_view__wrapper">
          <!-- GUIDE DESKTOP -->
          <div :class="styles.about_view__guide_desktop">
            <a
              :key="index"
              v-for="(guide, index) in listGuide"
              :class="[
                styles.about_view__guide_item_deskop,
                activeGuide === index ? styles.guide_desktop__active : '',
              ]"
              @click="setActiveGuide(index)"
              :href="guide.link"
            >
              {{ guide.name }}
            </a>
          </div>
          <!-- GUIDE DESKTOP -->

          <breadcrumbs :items="listBreadcrumb" />

          <div id="about" :class="styles.about_view__about_inner">
            <div :class="styles.about_view__about_text">
              <h1>Tentang Agrofarm</h1>
              <div
                :class="styles.about_view__about_desc"
                v-if="about && about.attributes"
                v-html="about.attributes.description"
              ></div>
            </div>
            <div :class="styles.about_view__about_desc_image">
              <img
                alt="Tentang Agrofarm"
                class="h-full"
                :src="require(`@/assets/content/about.png`)"
              />
            </div>
          </div>
        </div>
      </div>

      <about-landing id="philosophy" :disableButton="true" />

      <visi-misi :data="about" :type="'visimisi'" />

      <Icare :data="ICareList" />

      <visi-misi :data="about" :type="'client'" />
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed } from "vue";
import styles from "./AboutsView.module.css";
import AboutLanding from "../landing/about/AboutLanding.vue";
import breadcrumbs from "@/components/ui/breadcrumb/BreadcrumbUi.vue";
import { useStore } from "vuex";
import VisiMisi from "./visimisi/VisiMisi.vue";
import Icare from "./icare/IcareView.vue";

export default {
  name: "AboutsView",
  components: {
    AboutLanding,
    breadcrumbs,
    VisiMisi,
    Icare
  },
  setup() {
    const store = useStore();
    const listBreadcrumb = ref([
      {
        title: "Dashboard",
        disabled: false,
        href: "/",
      },
      {
        title: "Tentang Agrofarm",
        disabled: true,
        href: "#",
      },
    ]);

    const listGuide = [
      { name: "Tentang", link: "#about" },
      { name: "Filosofi", link: "#philosophy" },
      { name: "Visi & Misi", link: "#visiMisi" },
      { name: "Nilai Perusahaan", link: "#icare" },
      { name: "Klien", link: "#client" },
    ];

    const ICareList = [
      { letter: "I", label: "Innovation", desc: "Kami siap mempelajari hal baru di tengah perubahan yang begitu cepat untuk menghadirkan nilai terbaik bagi stakeholder" },
      { letter: "C", label: "Collaboration", desc: "Kami saling menghargai dan siap bekerjasama dengan siapapun." },
      { letter: "A", label: "Accountability", desc: "Kami dapat diandalkan untuk menuntaskan setiap tanggung jawab dengan hasil terbaik" },
      { letter: "R", label: "Religious", desc: "Kami melandasi setiap aktivitas hidup kami pada ketaatan terhadap Tuhan Yang Maha Esa" },
      { letter: "E", label: "Empathy", desc: "Kami memahami kebutuhan pelanggan dan menghadirkan pelayanan yang terbaik" },
    ];

    const activeGuide = ref(0);

    const about = computed(() => {
      return store.getters.getAbout;
    });

    const setActiveGuide = (index) => {
      activeGuide.value = index;
    };

    onMounted(() => {
      console.log('Mounted AboutsView', about);
      store.dispatch("getAbout");
    });

    return {
      styles,
      listBreadcrumb,
      listGuide,
      activeGuide,
      setActiveGuide,
      about,
      ICareList
    };
  },
};
</script>