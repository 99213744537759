<template>
    <a href="#" :class="styles.article_landing">
        <div :class="styles.article_landing__inner">
            <breadcrumbs :items="listBreadcrumb" />
            <div :class="styles.article_landing__hero">
                <h1>Pusat Informasi dan Berita Agrofarm</h1>
                <p>Sumber cerita dari pengalaman petani, inovatif, dan tips & tutorial seputar petani</p>

                <div :class="styles.article_landing__hero_news">
                    <big-card-article :data="heroArticle" />
                </div>

                <div :class="styles.article_landing__lists_news">
                    <div :class="styles.article_landing__lists_news_left">
                        <card-articles :data="listsArticle" />
                    </div>

                    <div :class="styles.article_landing__lists_news_right">
                        <div :class="styles.article_landing__category">
                            <div :class="styles.article_landing__category_inner">
                                <div :class="styles.article_landing__category_title">Kategori Artikel</div>
                                <div :class="styles.article_landing__category_lists">
                                    <div 
                                    :key="item"
                                    v-for="item in listCategory" :class="styles.article_landing__category_item">
                                        {{ item }}    
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </a>
</template>
<script>
import { ref, computed, onMounted, watch } from "vue";
import styles from "./ArticleLanding.module.css"
import breadcrumbs from "@/components/ui/breadcrumb/BreadcrumbUi.vue";
import BigCardArticle from '@/components/ui/articles/bigCardArticle/BigCardArticle.vue';
import CardArticles from '@/components/ui/articles/CardArticles.vue';
import { useStore } from "vuex";

export default {
    name: "ArticleLanding",
    components: { 
        breadcrumbs,
        BigCardArticle,
        CardArticles
     },
    setup() {
        const store = useStore();

        const getCategory = computed(() => {
            return store.getters.getCategoryArticle;
        });

        const listArticle = computed(() => {
            return store.getters.getListArticle;
        });

        const heroArticle = computed(() => {
            return listArticle.value.slice(0, 1); 
        });

        const listsArticle = computed(() => {
            return listArticle.value.slice(1, 4);
        });

        const listBreadcrumb = ref([
            {
                title: "Homepage",
                disabled: false,
                href: "/",
            },
            {
                title: "Artikel",
                disabled: false,
                href: "#",
            }
        ]);

        watch(
            () => [listArticle.value],
            () => {
                if (listArticle.value.length === 0) {
                store.commit("setFilterArticle", "Berita");
                store.dispatch("getListArticle");
                }
            }
        );

        onMounted(async () => {
            store.commit("setSizeArticle", 10);
            store.dispatch("getListArticle");
        });

        const listCategory = ["Berita", "Artikel", "Ortani", "Tips & Tutorial"];

        return {
            styles,
            breadcrumbs,
            listBreadcrumb,
            BigCardArticle,
            listArticle,
            heroArticle,
            listsArticle,
            getCategory,
            listCategory
        };
    }
};
</script>