<template>
  <div :class="styles.product_item__wrapper">
    <div :class="styles.product_item__top">
      <img :src="imageSrc" :alt="label" />
      <div
        :class="[
          styles.product_item__top_label,
          styles[label.replace(/ /g, '_').toLowerCase()],
        ]"
      >
        {{ label }}
      </div>
    </div>
    <div class="relative">
      <div v-if="comingSoon" :class="styles.product_item__coming_soon">Segera Hadir</div>
      <div :class="styles.product_item__bottom">
        <!-- <div v-if=""></div> -->
        <div :class="styles.product_item__bottom_text">
          <div :class="styles.product_item__title">{{ title }}</div>
          <div :class="styles.product_item__desc" v-html="description"></div>
        </div>

        <div :class="styles.product_item__bottom_btn">
          <a :href="link">
            Lihat Detail
            <div :class="styles.icon_arrow" />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import styles from "./ProductList.ui.module.css";

export default {
  name: "ProductListUi",
  props: {
    imageSrc: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    link: {
      type: String,
      required: true,
    },
    comingSoon: {
      type: Boolean,
      required: true,
    },
  },
  setup() {
    return {
      styles,
    };
  },
};
</script>
