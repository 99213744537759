<template>
  <div>
    <div class="">{{ label }}</div>
    <div class="border rounded p-4" v-if="editor">
      <div>
        <button
          @click="editor.chain().focus().toggleBold().run()"
          class="border border-black-agro rounded p-1 mr-1 mb-1"
          type="button"
          :class="{ 'is-active': editor.isActive('bold') }"
        >
          B
        </button>
        <button
          @click="editor.chain().focus().toggleItalic().run()"
          class="border border-black-agro rounded p-1 mr-1 mb-1"
          type="button"
          :class="{ 'is-active': editor.isActive('italic') }"
        >
          I
        </button>
        <button
          @click="editor.chain().focus().toggleStrike().run()"
          class="border border-black-agro rounded p-1 mr-1 mb-1"
          type="button"
          :class="{ 'is-active': editor.isActive('strike') }"
        >
          <strike>S</strike>
        </button>
        <button
          @click="editor.chain().focus().toggleCode().run()"
          class="border border-black-agro rounded p-1 mr-1 mb-1"
          type="button"
          :class="{ 'is-active': editor.isActive('code') }"
        >
          C
        </button>
        <button
          @click="editor.chain().focus().unsetAllMarks().run()"
          class="border border-black-agro rounded p-1 mr-1 mb-1"
          type="button"
        >
          clear marks
        </button>
        <button
          @click="editor.chain().focus().clearNodes().run()"
          class="border border-black-agro rounded p-1 mr-1 mb-1"
          type="button"
        >
          clear nodes
        </button>
        <button
          @click="editor.chain().focus().setParagraph().run()"
          class="border border-black-agro rounded p-1 mr-1 mb-1"
          type="button"
          :class="{ 'is-active': editor.isActive('paragraph') }"
        >
          paragraph
        </button>
        <button
          @click="editor.chain().focus().toggleHeading({ level: 1 }).run()"
          class="border border-black-agro rounded p-1 mr-1 mb-1"
          type="button"
          :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }"
        >
          H1
        </button>
        <button
          @click="editor.chain().focus().toggleHeading({ level: 2 }).run()"
          class="border border-black-agro rounded p-1 mr-1 mb-1"
          type="button"
          :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }"
        >
          H2
        </button>
        <button
          @click="editor.chain().focus().toggleHeading({ level: 3 }).run()"
          class="border border-black-agro rounded p-1 mr-1 mb-1"
          type="button"
          :class="{ 'is-active': editor.isActive('heading', { level: 3 }) }"
        >
          H3
        </button>
        <button
          @click="editor.chain().focus().toggleHeading({ level: 4 }).run()"
          class="border border-black-agro rounded p-1 mr-1 mb-1"
          type="button"
          :class="{ 'is-active': editor.isActive('heading', { level: 4 }) }"
        >
          H4
        </button>
        <button
          @click="editor.chain().focus().toggleHeading({ level: 5 }).run()"
          class="border border-black-agro rounded p-1 mr-1 mb-1"
          type="button"
          :class="{ 'is-active': editor.isActive('heading', { level: 5 }) }"
        >
          H5
        </button>
        <button
          @click="editor.chain().focus().toggleHeading({ level: 6 }).run()"
          class="border border-black-agro rounded p-1 mr-1 mb-1"
          type="button"
          :class="{ 'is-active': editor.isActive('heading', { level: 6 }) }"
        >
          H6
        </button>
        <button
          @click="editor.chain().focus().toggleBulletList().run()"
          class="border border-black-agro rounded p-1 mr-1 mb-1"
          type="button"
          :class="{ 'is-active': editor.isActive('bulletList') }"
        >
          bullet list
        </button>
        <button
          @click="editor.chain().focus().toggleOrderedList().run()"
          class="border border-black-agro rounded p-1 mr-1 mb-1"
          type="button"
          :class="{ 'is-active': editor.isActive('orderedList') }"
        >
          ordered list
        </button>
        <button
          @click="editor.chain().focus().toggleCodeBlock().run()"
          class="border border-black-agro rounded p-1 mr-1 mb-1"
          type="button"
          :class="{ 'is-active': editor.isActive('codeBlock') }"
        >
          code block
        </button>
        <button
          @click="editor.chain().focus().toggleBlockquote().run()"
          class="border border-black-agro rounded p-1 mr-1 mb-1"
          type="button"
          :class="{ 'is-active': editor.isActive('blockquote') }"
        >
          blockquote
        </button>
        <button
          @click="editor.chain().focus().setHorizontalRule().run()"
          class="border border-black-agro rounded p-1 mr-1 mb-1"
          type="button"
        >
          horizontal rule
        </button>
        <button
          @click="editor.chain().focus().setHardBreak().run()"
          class="border border-black-agro rounded p-1 mr-1 mb-1"
          type="button"
        >
          hard break
        </button>
        <button
          @click="editor.chain().focus().undo().run()"
          class="border border-black-agro rounded p-1 mr-1 mb-1"
          type="button"
        >
          undo
        </button>
        <button
          @click="editor.chain().focus().redo().run()"
          class="border border-black-agro rounded p-1 mr-1 mb-1"
          type="button"
        >
          redo
        </button>
      </div>
      <editor-content :editor="editor" />
    </div>
  </div>
</template>

<script>
import StarterKit from "@tiptap/starter-kit";
import { Editor, EditorContent } from "@tiptap/vue-3";

export default {
  components: {
    EditorContent,
  },

  props: {
    modelValue: {
      type: String,
      default: "",
    },
    label: {
      Type: String,
    },
  },

  emits: ["update:modelValue"],

  data() {
    return {
      editor: null,
    };
  },

  watch: {
    modelValue(value) {
      const isSame = this.editor.getHTML() === value;

      if (isSame) {
        return;
      }

      this.editor.commands.setContent(value, false);
    },
  },

  mounted() {
    this.editor = new Editor({
      extensions: [StarterKit],
      content: this.modelValue,
      onUpdate: () => {
        this.$emit("update:modelValue", this.editor.getHTML());
      },
      editorProps: {
        attributes: {
          class:
            "h-40 mt-4 border p-4",
          spellcheck: "false",
        },
      },
    });
  },

  beforeUnmount() {
    this.editor.destroy();
  },
};
</script>

<style lang="scss">
/* Basic editor styles */
.tiptap {
  > * + * {
    margin-top: 0.75em;
  }

  ul,
  ol {
    padding: 0 1rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
  }

  code {
    background-color: rgba(#616161, 0.1);
    color: #616161;
  }

  pre {
    background: #0d0d0d;
    color: #fff;
    font-family: "JetBrainsMono", monospace;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;

    code {
      color: inherit;
      padding: 0;
      background: none;
      font-size: 0.8rem;
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }

  hr {
    margin: 1rem 0;
  }

  blockquote {
    padding-left: 1rem;
    border-left: 2px solid rgba(#0d0d0d, 0.1);
  }
}
</style>