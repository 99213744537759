<template>
    <div :class="cn(styles.visi_misi, styles[type])">
        <div :class="cn(styles.visi_misi__inner, styles[type])">
            <div 
            v-if="type === 'visimisi'"
            :class="styles.visi_misi__top" id="visiMisi">
                <div :class="styles.visi_misi__text">
                    <div :class="styles.visi_misi__text_item">
                        <div :class="styles.visi_misi__text_title">Visi</div>
                        <div v-if="data && data.attributes" :class="styles.visi_misi__text_content"
                            v-html="data.attributes.vision"></div>
                    </div>

                    <div :class="styles.visi_misi__text_item">
                        <div :class="styles.visi_misi__text_title">Misi</div>
                        <div v-if="data && data.attributes" :class="styles.visi_misi__text_content"
                            v-html="data.attributes.mission"></div>
                    </div>
                </div>

                <div :class="styles.visi_misi__images">
                    <img alt="Visi Misi" class="h-full" :src="require(`@/assets/AboutVisi.svg`) " />
                </div>
            </div>

            <div 
            v-if="type === 'client'"
            :class="styles.visi_misi__bottom" id="client">
                <div :class="styles.visi_misi__text_client">
                    Agrofarm dipercaya oleh perusahaan pertanian terkemuka:
                </div>

                <div :class="styles.visi_misi__client">
                    <div :key="index" v-for="(index) in 11" :class="styles.visi_misi__client_item">
                        <img :src="require(`@/assets/clients/client-${index}.jpg`)" :alt="`client-${index}`">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import styles from "./VisiMisi.module.css";
import classNames from 'classnames';

export default {
    name: "VisiMisi",
    props: {
        data: {
            type: [Array, Object],
            required: true
        },
        type: {
            type: String,
            required: true
        }
    },
    setup() {
        return {
            styles,
            cn: classNames
        };
    }
};
</script>