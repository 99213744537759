<template>
  <navbar-home />
  <div class="mt-[76px]"></div>
  <product-detail-vue />
  <footer-landing />
</template>

<script>
import NavbarHome from "@/layouts/NavbarHome.vue";
import ProductDetailVue from "@/components/pages/product/detail/ProductDetail.vue"
import FooterLanding from "@/components/FooterLanding.vue";

export default {
    name: "ProductDetail",
  components: { NavbarHome, ProductDetailVue, FooterLanding },
    setup() {
        return {};
    }
};
</script>
