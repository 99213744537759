import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import VueSocials from 'vue-socials';
import VueSocialSharing from 'vue-social-sharing'
import { loadFonts } from './plugins/webfontloader';
import '@/assets/tailwind.css';
import '@/assets/index.css';
import '@/assets/scrollclient.css';
import 'vue3-carousel/dist/carousel.css';
import VueGtag from 'vue-gtag';

loadFonts();

createApp(App)
	.use(router)
	.use(store)
	.use(vuetify)
	.use(VueSocials)
	.use(VueSocialSharing)
	.use(VueGtag, {
		config: { id: 'G-GLM2PKQ5NG' }
	}, router)
	.mount('#app');
