<template>
  <div class="flex flex-col gap-[10px]" @click="handleDetail">
    <img
      :src="props.detail.attributes.photos"
      class="w-full h-[170px] object-cover rounded-md"
    />
    <div
      class="bg-ascent-500 px-3 py-1 rounded text-[11px] font-semibold text-black-agro w-fit"
    >
      {{ props.detail.attributes.category }}
    </div>
    <div class="text-lg font-bold text-black leading-7">
      {{ limitString(props.detail.attributes.title, 50) }}
    </div>
    <div
      class="text-sm color-gray-agro height leading-6"
      v-html="limitString(props.detail.attributes.news || '', 150)"
    />
  </div>
</template>
<!-- eslint-disable no-undef -->
<script setup>
import formats from "@/modules/formats.js";
import { useRouter } from "vue-router";

const props = defineProps(["detail"]);
const router = useRouter();
const { limitString } = formats();

const handleDetail = () => {
  router.push(`/article/${props.detail.id}`);
};
</script>