<template>
  <navbar-home />
  <header-landing />
  <carousel-landing />
  <about-landing />
  <list-product-landing />
  <list-testimonial-landing />
  <resource-landing />
  <client-landing />
  <footer-landing />
</template>

<script>
import { onMounted } from "vue";
import { useStore } from "vuex";
import NavbarHome from "@/layouts/NavbarHome.vue";
import HeaderLanding from "@/components/HeaderLanding.vue";
import CarouselLanding from "@/components/pages/landing/carousel/CarouselLanding.vue"; 
import AboutLanding from "@/components/pages/landing/about/AboutLanding.vue"
import ListProductLanding from "@/components/Products/ListProductLanding.vue";
import ListTestimonialLanding from "@/components/Testimonial/ListTestimonialLanding.vue";
import ResourceLanding from "@/components/Resource/ResourceLanding.vue";
import ClientLanding from "@/components/Client/ClientLanding.vue";
import FooterLanding from "@/components/FooterLanding.vue";

export default {
  name: "HomeView",
  components: {
    NavbarHome,
    HeaderLanding,
    CarouselLanding,
    AboutLanding,
    ListProductLanding,
    ListTestimonialLanding,
    ResourceLanding,
    ClientLanding,
    FooterLanding,
  },
  setup() {
    const store = useStore();

    onMounted(async () => {
      store.commit("setSizeProduct", 5);
      store.dispatch("getListProduct");
      store.commit("setSizeTestimony", 3);
      store.dispatch("getListTestimony");
      store.commit("setSizeArticle", 3);
      store.dispatch("getListArticle");
    });
  },
};
</script>
