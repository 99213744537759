<template>
  <navbar-home />
  <div class="mt-[76px]"></div>
  <abouts-view />
  <footer-landing />
</template>

<script>
import NavbarHome from "@/layouts/NavbarHome.vue";
import FooterLanding from "@/components/FooterLanding.vue";
import AboutsView from '@/components/pages/about/AboutsView.vue';

export default {
  name: "AboutView",
  components: {
    NavbarHome,
    FooterLanding,
    AboutsView
  },
  setup() {
    return {};
  }
};
</script>