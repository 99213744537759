<template>
  <div class="h-screen w-screen bg-grayscale-50">
    <div
      class="h-full flex items-center justify-center m-auto px-5 sm:px-0 sm:w-[640px]"
    >
      <div class="w-full bg-white rounded-xl py-5 px-5 sm:py-[70px] sm:px-16">
        <img
          alt="agro-logo"
          :src="require(`@/assets/Logo.svg`)"
          class="m-auto"
        />
        <div class="w-full mt-8 sm:mt-12 flex flex-col justify-center">
          <v-card class="p-4">
            <h3 class="text-center text-3xl font-bold">Masuk Akun</h3>
            <v-form @submit.prevent>
              <v-text-field
                v-model="form.username"
                :rules="[(v) => !!v || 'Wajib diisi']"
                label="Username"
                variant="underlined"
              />
              <v-text-field
                v-model="form.password"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[(v) => !!v || 'Kode Password Harus Diisi']"
                :type="showPassword ? 'text' : 'password'"
                label="Password"
                variant="underlined"
                @click:append="showPassword = !showPassword"
              />
              <v-btn
                type="submit"
                block
                class="mt-4"
                variant="outlined"
                @click="login"
              >
                Login
              </v-btn>
            </v-form>
          </v-card>
        </div>
      </div>
    </div>
    <v-alert text="asd" variant="tonal"></v-alert>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useStore } from "vuex";

const store = useStore();

const form = ref({
  username: "",
  password: "",
});

function login() {
  store.dispatch("login", form.value).then((res) => {
    if (res.data.success) {
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  });
}

const showPassword = false;
</script>