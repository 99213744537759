<template>
  <navbar-home />
  <div class="mt-[76px]"></div>
  <article-landing />
  <footer-landing />
</template>
  
<script>
import NavbarHome from "@/layouts/NavbarHome.vue";
import FooterLanding from "@/components/FooterLanding.vue";
import ArticleLanding from '@/components/pages/articles/landing/ArticleLanding.vue';

export default {
    name: "ArticleView",
    components: { 
      ArticleLanding,
      NavbarHome, 
      FooterLanding 
    },
    setup() {
        return {};
    }
};
</script>