<template>
    <div :class="styles.icare" id="icare">
        <div :class="styles.icare__inner">
            <div :class="styles.icare__title">Nilai Perusahaan</div>
            <div :class="styles.icare__content">
                <div 
                :key="index"
                v-for="(item, index) in data"
                :class="styles.icare__content_box">
                    <div :class="styles.icare__content_box_top">
                        {{ item.letter }}
                    </div>                
                    <div :class="styles.icare__content_box_bottom">
                        <div :class="styles.icare__bottom_title">{{ item.label }}</div>
                        <div :class="styles.icare__bottom_desc">{{ item.desc }}</div>
                    </div>                
                </div>
            </div>        
        </div>
    </div>
</template>
  
<script>
    import styles from "./IcareView.module.css"

    export default {
        name: "IcareView",
        props: {
            data: {
                type: [Array, Object],
                required: true
            }
        },
        setup(props) {
            console.log(props);

            return {
                styles
            };
        }
    };
</script>