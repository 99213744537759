<template>
  <v-card>
    <v-layout>
      <v-navigation-drawer expand-on-hover rail>
        <router-link to="/">
          <img
            alt="agro-logo"
            :src="require(`@/assets/Logo.svg`)"
            class="m-auto mt-4"
          />
        </router-link>
        <v-divider></v-divider>
        <v-list density="compact" nav>
          <router-link to="/admin">
            <v-list-item prepend-icon="mdi-star" title="Testimony" />
          </router-link>
          <router-link to="/admin/product">
            <v-list-item prepend-icon="mdi-folder" title="Product" />
          </router-link>
          <router-link to="/admin/article">
            <v-list-item prepend-icon="mdi-note" title="Article" />
          </router-link>
          <v-list-item
            prepend-icon="mdi-logout"
            title="Keluar"
            class="cursor-pointer"
            @click="logout"
          />
        </v-list>
      </v-navigation-drawer>
      <v-main class="min-h-screen">
        <div class="p-4">
          <h3 class="text-3xl">Testimony</h3>
          <div class="mt-4">
            <v-btn prepend-icon="mdi-plus" @click="handleDialogAdd">
              Tambah Data
            </v-btn>
          </div>
          <v-data-table
            class="border p-4 mt-4"
            :headers="headers"
            :items="listTestimony"
          >
            <template v-slot:item="{ item }">
              <tr>
                <td>
                  <img
                    class="object-cover"
                    :src="item.selectable.photos"
                    height="75"
                    width="100"
                  />
                </td>
                <td>{{ item.selectable.name }}</td>
                <td>{{ item.selectable.plant }}</td>
                <td>{{ item.selectable.location }}</td>
                <td>{{ item.selectable.testimony }}</td>
                <td>
                  <v-icon
                    size="small"
                    class="me-2"
                    @click="handleDialogView(item.selectable)"
                  >
                    mdi-eye
                  </v-icon>
                  <!-- <v-icon
                    size="small"
                    class="me-2"
                    @click="handleDialogEdit(item.selectable)"
                  >
                    mdi-pencil
                  </v-icon> -->
                  <v-icon
                    size="small"
                    @click="handleDialogDelete(item.selectable)"
                  >
                    mdi-delete
                  </v-icon>
                </td>
              </tr>
            </template>
          </v-data-table>
        </div>
        <!-- view -->
        <v-dialog v-model="showDialogView" width="600">
          <v-card>
            <v-card-title> Detail Data </v-card-title>
            <v-card-text>
              <div class="grid grid-cols-6 gap-4">
                <div class="col-span-1">Petani</div>
                <div class="col-span-5 font-bold">{{ form.name }}</div>
                <div class="col-span-1">Tanaman</div>
                <div class="col-span-5 font-bold">{{ form.plant }}</div>
                <div class="col-span-1">Lokasi</div>
                <div class="col-span-5 font-bold">{{ form.location }}</div>
                <div class="col-span-1">Testimoni</div>
                <div class="col-span-5 font-bold">{{ form.testimony }}</div>
              </div>
            </v-card-text>
            <v-card-actions class="ml-auto">
              <v-btn color="secondary" @click="handleDialogView()">
                Tutup
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- add -->
        <v-dialog v-model="showDialogAdd" width="600">
          <v-card>
            <v-card-title> Tambah Data </v-card-title>
            <v-form v-model="valid" @submit.prevent="addTestimony">
              <v-card-text>
                <v-text-field
                  v-model="form.name"
                  class="mt-2"
                  :rules="[(v) => !!v || 'Wajib diisi']"
                  label="Nama Petani"
                  variant="outlined"
                  required
                />
                <v-text-field
                  v-model="form.plant"
                  class="mt-2"
                  :rules="[(v) => !!v || 'Wajib diisi']"
                  label="Jenis Tanaman"
                  variant="outlined"
                  required
                />
                <v-text-field
                  v-model="form.location"
                  class="mt-2"
                  :rules="[(v) => !!v || 'Wajib diisi']"
                  label="Lokasi"
                  variant="outlined"
                  required
                />
                <v-textarea
                  v-model="form.testimony"
                  class="mt-2"
                  :rules="[(v) => !!v || 'Wajib diisi']"
                  label="Testimoni"
                  variant="outlined"
                  required
                />
              </v-card-text>
              <v-card-actions class="justify-end">
                <v-btn color="secondary" @click="handleDialogAdd">
                  Batal
                </v-btn>
                <v-btn color="primary" type="submit"> Tambah Data </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog>
        <!-- edit -->
        <v-dialog v-model="showDialogEdit" width="600">
          <v-card>
            <v-card-title> Ubah Data </v-card-title>
            <v-form v-model="valid" @submit.prevent="editTestimony">
              <v-card-text>
                <v-text-field
                  v-model="form.name"
                  class="mt-2"
                  :rules="[(v) => !!v || 'Wajib diisi']"
                  label="Nama Petani"
                  variant="outlined"
                  required
                />
                <v-text-field
                  v-model="form.plant"
                  class="mt-2"
                  :rules="[(v) => !!v || 'Wajib diisi']"
                  label="Jenis Tanaman"
                  variant="outlined"
                  required
                />
                <v-text-field
                  v-model="form.location"
                  class="mt-2"
                  :rules="[(v) => !!v || 'Wajib diisi']"
                  label="Lokasi"
                  variant="outlined"
                  required
                />
                <v-textarea
                  v-model="form.testimony"
                  class="mt-2"
                  :rules="[(v) => !!v || 'Wajib diisi']"
                  label="Testimoni"
                  variant="outlined"
                  required
                />
              </v-card-text>
              <v-card-actions class="justify-end">
                <v-btn color="secondary" @click="handleDialogEdit()">
                  Batal
                </v-btn>
                <v-btn color="primary" type="submit"> Ubah Data </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog>
        <!-- delete -->
        <v-dialog v-model="showDialogDelete" width="600">
          <v-card>
            <v-card-title> Hapus Data </v-card-title>
            <v-card-text>
              <div class="grid grid-cols-6 gap-4">
                <div class="col-span-1">Petani</div>
                <div class="col-span-5 font-bold">{{ form.name }}</div>
                <div class="col-span-1">Tanaman</div>
                <div class="col-span-5 font-bold">{{ form.plant }}</div>
                <div class="col-span-1">Lokasi</div>
                <div class="col-span-5 font-bold">{{ form.location }}</div>
                <div class="col-span-1">Testimoni</div>
                <div class="col-span-5 font-bold">{{ form.testimony }}</div>
              </div>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn color="secondary" @click="handleDialogDelete()">
                Batal
              </v-btn>
              <v-btn color="primary" @click="deleteTestimony"> Hapus </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- snackbar -->
        <v-snackbar v-model="showSnackbar" location="top right" timeout="2000">
          {{ snackbar }}
          <template v-slot:actions>
            <v-btn color="blue" variant="text" @click="showSnackbar = false">
              Close
            </v-btn>
          </template>
        </v-snackbar>
      </v-main>
    </v-layout>
  </v-card>
</template>

<script setup>
import { ref, onMounted, computed } from "vue";
import { VDataTable } from "vuetify/labs/VDataTable";
import { useStore } from "vuex";

const store = useStore();

const snackbar = ref();
const showSnackbar = ref(false);

function loadData() {
  store.commit("setSizeTestimony", 10);
  store.dispatch("getListTestimony");
}

const headers = ref([
  {
    title: "Foto",
    align: "start",
    sortable: false,
    key: "photos",
  },
  { title: "Petani", key: "name" },
  { title: "Tanaman", key: "plant" },
  { title: "Lokasi", key: "location" },
  { title: "Testimoni", key: "testimony" },
  { title: "Actions", key: "actions", sortable: false },
]);

const valid = ref(false);

const unique = ref(null);

const form = ref({
  name: "",
  plant: "",
  location: "",
  photos: "https://google.photos",
  testimony: "",
});

// view

const showDialogView = ref(false);

function handleDialogView(data) {
  if (showDialogView.value) {
    form.value.name = "";
    form.value.plant = "";
    form.value.location = "";
    form.value.photos = "https://google.photos";
    form.value.testimony = "";
    unique.value = null;
  } else {
    form.value.name = data.name;
    form.value.plant = data.plant;
    form.value.location = data.location;
    form.value.photos = data.photos;
    form.value.testimony = data.testimony;
    unique.value = data.unique;
  }
  showDialogView.value = !showDialogView.value;
}

// add

const showDialogAdd = ref(false);

function handleDialogAdd() {
  if (showDialogAdd.value) {
    form.value.name = "";
    form.value.plant = "";
    form.value.location = "";
    form.value.photos = "https://google.photos";
    form.value.testimony = "";
  }
  showDialogAdd.value = !showDialogAdd.value;
}

function addTestimony() {
  if (valid.value) {
    store.dispatch("addListTestimony", form.value).then((res) => {
      if (res.data.success) {
        loadData();
        handleDialogAdd();
        snackbar.value = "Berhasil ditambahkan";
        showSnackbar.value = true;
      }
    });
  }
}

// edit

const showDialogEdit = ref(false);

function handleDialogEdit(data) {
  if (showDialogEdit.value) {
    form.value.name = "";
    form.value.plant = "";
    form.value.location = "";
    form.value.photos = "https://google.photos";
    form.value.testimony = "";
    unique.value = null;
  } else {
    form.value.name = data.name;
    form.value.plant = data.plant;
    form.value.location = data.location;
    form.value.photos = data.photos;
    form.value.testimony = data.testimony;
    unique.value = data.unique;
  }
  showDialogEdit.value = !showDialogEdit.value;
}

function editTestimony() {
  if (valid.value) {
    store
      .dispatch("editListTestimony", {
        ...form.value,
        unique: unique.value,
      })
      .then((res) => {
        if (res.data.success) {
          loadData();
          handleDialogEdit();
          snackbar.value = "Berhasil diubah";
          showSnackbar.value = true;
        }
      });
  }
}

// delete

const showDialogDelete = ref(false);

function handleDialogDelete(data) {
  if (showDialogDelete.value) {
    form.value.name = "";
    form.value.plant = "";
    form.value.location = "";
    form.value.photos = "https://google.photos";
    form.value.testimony = "";
    unique.value = null;
  } else {
    form.value.name = data.name;
    form.value.plant = data.plant;
    form.value.location = data.location;
    form.value.photos = data.photos;
    form.value.testimony = data.testimony;
    unique.value = data.unique;
  }
  showDialogDelete.value = !showDialogDelete.value;
}

function deleteTestimony() {
  store.dispatch("deleteListTestimony", unique.value).then((res) => {
    if (res.data.success) {
      loadData();
      handleDialogDelete();
      snackbar.value = "Berhasil dihapus";
      showSnackbar.value = true;
    }
  });
}

const logout = () => {
  localStorage.removeItem("agrofarm");
  window.location.reload();
};

const listTestimony = computed(() => {
  return store.getters.getListTestimony;
});

onMounted(async () => {
  loadData();
});
</script>