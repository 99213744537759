<template>
    <div :class="styles.another_product__section">
        <div :class="styles.another_product__section_inner">
            <div :class="styles.another_product__title">
                <h3>Pupuk Lain yang Sejenis</h3>
            </div>
            
            <div :class="styles.another_product__list">
                <div :class="styles.another_product__list_mobile">
                    <product-list-ui 
                        v-for="(data, key) in mobileArray" :key="key"
                        :imageSrc="data.attributes.photos[0]"
                        :label="data.attributes.category"
                        :title="data.attributes.name"
                        :description="data.attributes.description"
                        :link="`/product/${data.id}`"
                    />
                </div>

                <div :class="styles.another_product__list_destop">
                    <product-list-ui 
                        v-for="(data, key) in desktopArray" :key="key"
                        :imageSrc="data.attributes.photos[0]"
                        :label="data.attributes.category"
                        :title="data.attributes.name"
                        :description="data.attributes.description"
                        :link="`/product/${data.id}`"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { onMounted, computed } from "vue";
import { useStore } from "vuex";
import styles from "./AnotherProduct.module.css";
import classNames from 'classnames';
import ProductListUi from "@/components/ui/product/list/ProductList.ui.vue";

export default {
    name: "AnotherProduct",
    components: {ProductListUi},
    setup() {
        const store = useStore();
        const listProduct = computed(() => {
            return store.getters.getListProduct;
        });

        const mobileArray = computed(() => {
            return listProduct.value.slice(0, 2);
        });

        const desktopArray = computed(() => {
            return listProduct.value.slice(0, 4);
        });

        onMounted(async () => {
            store.commit("setSizeProduct", 4);
            store.dispatch("getListProduct");
        });

        return {
            styles,
            cn: classNames,
            mobileArray,
            desktopArray
        };
    }
};
</script>