import axios from 'axios';

export default {
	state: {
		pageSustainbility: 1,
		sizeSustainbility: 10,
		listSustainbility: [],
	},
	getters: {
		getPageSustainbility: (state) => {
			return state.pageSustainbility;
		},
		getSizeSustainbility: (state) => {
			return state.sizeSustainbility;
		},
		getListSustainbility: (state) => {
			return state.listSustainbility;
		},
	},
	actions: {
		async callListSustainbility({ state, commit }) {
			const params = new URLSearchParams({
				'pagination[page]': state.pageSustainbility,
				'pagination[pageSize]': state.sizeSustainbility,
			});
			const url =
				process.env.VUE_APP_NEW_API_URL +
				`/sustainabilities?` +
				params.toString();

			return new Promise((resolve, reject) => {
				axios({
					url: url,
					method: 'GET',
				})
					.then((res) => {
						if (res.status === 200) {
							commit('setListSustainbility', res.data.data);
							resolve(res.data.data);
						}
					})
					.catch((err) => {
						reject(err);
					});
			});
		},
	},
	mutations: {
		setPageSustainbility: (state, data) => {
			state.pageSustainbility = data;
		},
		setSizeSustainbility: (state, data) => {
			state.sizeSustainbility = data;
		},
		setListSustainbility: (state, data) => {
			state.listSustainbility = data;
		},
	},
};
