<template>
  <navbar-home />
  <div class="bg-grey-agro py-4 mt-[20px] lg:mt-[76px]">
    <div class="container px-6 mx-auto mt-7">
      <v-breadcrumbs class="text-sm p-0" :items="listBreadcrumb" />
      <div class="mt-7 text-2xl lg:text-[40px] font-bold">Agrofarm karir</div>
      <div class="mt-4 text-base lg:text-lg font-normal">
        Temukan beragam peluang karir yang menarik sesuai dengan kemampuan Anda
      </div>
      <div
        class="mt-10 md:mt-7 px-5 md:px-10 rounded-[28px] mb-[100px] bg-white"
      >
        <divn
          class="block md:grid md:grid-cols-5 md:gap-4 items-center py-7"
          :class="key !== listCareer.length - 1 && 'border-b'"
          v-for="(item, key) in listCareer"
          :key="key"
        >
          <div class="md:col-span-2 font-bold">{{ item.attributes.name }}</div>
          <div class="mt-3 md:mt-0">{{ item.attributes.division }}</div>
          <div class="text-left md:text-center mt-3 md:mt-0">
            <v-icon icon="mdi-map-marker" size="small" class="mr-1" />
            {{ item.attributes.location }}
          </div>
          <a :href="item.attributes.url" target="_blank">
            <v-btn
              class="rounded-full h-auto text-sm py-2 px-4 mt-3 md:mt-0"
              variant="outlined"
              append-icon="mdi-arrow-top-right"
            >
              Lihat Detail
            </v-btn>
          </a>
        </divn>
      </div>
    </div>
  </div>
  <footer-landing />
</template>

<script>
import { onMounted, computed } from "vue";
import { useStore } from "vuex";
import NavbarHome from "@/layouts/NavbarHome.vue";
import FooterLanding from "@/components/FooterLanding.vue";

export default {
  name: "AgromonisView",
  components: { NavbarHome, FooterLanding },
  setup() {
    const store = useStore();

    const listCareer = computed(() => {
      return store.getters.getListCareer;
    });

    const listBreadcrumb = [
      {
        title: "Homepage",
        disabled: false,
        href: "/",
      },
      {
        title: "Karir",
        disabled: true,
        href: "career",
      },
    ];

    onMounted(async () => {
      store.commit("setSizeCareer", 10);
      store.dispatch("getListCareer");
    });

    return { listCareer, listBreadcrumb };
  },
};
</script>