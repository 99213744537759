<template>
  <v-card>
    <v-layout>
      <v-navigation-drawer expand-on-hover rail>
        <router-link to="/">
          <img
            alt="agro-logo"
            :src="require(`@/assets/Logo.svg`)"
            class="m-auto mt-4"
          />
        </router-link>
        <v-divider></v-divider>
        <v-list density="compact" nav>
          <router-link to="/admin">
            <v-list-item prepend-icon="mdi-star" title="Testimony" />
          </router-link>
          <router-link to="/admin/product">
            <v-list-item prepend-icon="mdi-folder" title="Product" />
          </router-link>
          <router-link to="/admin/article">
            <v-list-item prepend-icon="mdi-note" title="Article" />
          </router-link>
          <v-list-item
            prepend-icon="mdi-logout"
            title="Keluar"
            class="cursor-pointer"
            @click="logout"
          />
        </v-list>
      </v-navigation-drawer>
      <v-main class="min-h-screen">
        <div class="p-4">
          <h3 class="text-3xl">Product</h3>
          <div class="mt-4">
            <v-btn prepend-icon="mdi-plus" @click="handleDialogAdd">
              Tambah Data
            </v-btn>
          </div>
          <v-data-table
            class="border mt-4"
            :headers="headers"
            :items="listProduct"
          >
            <template v-slot:item="{ item }">
              <tr>
                <td>
                  <img
                    class="object-cover"
                    :src="item.selectable.photos"
                    height="75"
                    width="100"
                  />
                </td>
                <td>{{ item.selectable.name }}</td>
                <td>{{ item.selectable.category }}</td>
                <td>
                  <div
                    v-for="(detail, index) in item.selectable.type"
                    :key="index"
                  >
                    {{ detail }}
                  </div>
                </td>
                <td>{{ item.selectable.pros.substring(0, 150) + "..." }}</td>
                <td>{{ item.selectable.howTo }}</td>
                <td>
                  <div
                    v-for="(detail, index) in item.selectable.plant"
                    :key="index"
                  >
                    {{ detail }}
                  </div>
                </td>
                <td>{{ item.selectable.highlight }}</td>
                <td>
                  <v-icon
                    size="small"
                    class="me-2"
                    @click="handleDialogView(item.selectable)"
                  >
                    mdi-eye
                  </v-icon>
                  <!-- <v-icon
                    size="small"
                    class="me-2"
                    @click="handleDialogEdit(item.selectable)"
                  >
                    mdi-pencil
                  </v-icon> -->
                  <v-icon
                    size="small"
                    @click="handleDialogDelete(item.selectable)"
                  >
                    mdi-delete
                  </v-icon>
                </td>
              </tr>
            </template>
          </v-data-table>
        </div>
        <!-- view -->
        <v-dialog v-model="showDialogView" width="600">
          <v-card>
            <v-card-title> Detail Data </v-card-title>
            <v-card-text>
              <div class="grid grid-cols-6 gap-4">
                <div class="col-span-1">Nama</div>
                <div class="col-span-5 font-bold">{{ form.name }}</div>
                <div class="col-span-1">Kategori</div>
                <div class="col-span-5 font-bold">{{ form.category }}</div>
                <div class="col-span-1">Deskripsi</div>
                <div class="col-span-5 font-bold">{{ form.description }}</div>
                <div class="col-span-1">Tipe</div>
                <div class="col-span-5 font-bold">
                  <div v-for="(detail, index) in form.type" :key="index">
                    {{ detail }}
                  </div>
                </div>
                <div class="col-span-1">Kelebihan</div>
                <div class="col-span-5 font-bold">{{ form.pros }}</div>
                <div class="col-span-1">Cara Menggunakan</div>
                <div class="col-span-5 font-bold">{{ form.howTo }}</div>
                <div class="col-span-1">Spesifikasi</div>
                <div class="col-span-5 font-bold">{{ form.specification }}</div>
                <div class="col-span-1">Tanaman</div>
                <div class="col-span-5 font-bold">
                  <div v-for="(detail, index) in form.plant" :key="index">
                    {{ detail }}
                  </div>
                </div>
                <div class="col-span-1">Sorotan</div>
                <div class="col-span-5 font-bold">{{ form.highlight }}</div>
              </div>
            </v-card-text>
            <v-card-actions class="ml-auto">
              <v-btn color="secondary" @click="handleDialogView()">
                Tutup
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- add -->
        <v-dialog v-model="showDialogAdd" width="800">
          <v-card>
            <v-card-title> Tambah Data </v-card-title>
            <v-form v-model="valid" @submit.prevent="addProduct">
              <v-card-text>
                <div class="grid gap-4">
                  <v-text-field
                    v-model="form.name"
                    class="mt-2"
                    :rules="[(v) => !!v || 'Wajib diisi']"
                    label="Nama Produk"
                    variant="outlined"
                  />
                  <v-select
                    v-model="form.category"
                    class="mt-2"
                    :rules="[(v) => !!v || 'Wajib diisi']"
                    label="Category"
                    :items="[
                      'Pupuk Hayati',
                      'Pupuk Micro',
                      'Pupuk Macro',
                      'Pupuk Organik',
                    ]"
                    variant="outlined"
                  />
                  <v-select
                    v-model="form.type"
                    class="mt-2"
                    :rules="[(v) => !!v || 'Wajib diisi']"
                    label="Tipe"
                    :items="['Lakaba 5/10', 'Lakaba 8/12/11', 'Lakaba 8/8/8']"
                    variant="outlined"
                    multiple
                  />
                  <v-select
                    v-model="form.plant"
                    class="mt-2"
                    :rules="[(v) => !!v || 'Wajib diisi']"
                    label="Tanaman"
                    :items="['Padi', 'Palawija']"
                    variant="outlined"
                    multiple
                  />
                  <TipTap v-model="form.description" label="Deskripsi" />
                  <TipTap v-model="form.pros" label="Kelebihan" />
                  <TipTap v-model="form.howTo" label="Cara Menggunakan" />
                  <TipTap v-model="form.specification" label="Spesifikasi" />
                  <TipTap v-model="form.highlight" label="Sorotan" />
                  <!-- <v-textarea
                    v-model="form.description"
                    class="mt-2"
                    :rules="[(v) => !!v || 'Wajib diisi']"
                    label="Deskripsi"
                    variant="outlined"
                    required
                  />
                  <v-textarea
                    v-model="form.pros"
                    class="mt-2"
                    :rules="[(v) => !!v || 'Wajib diisi']"
                    label="Kelebihan"
                    variant="outlined"
                    required
                  />
                  <v-textarea
                    v-model="form.howTo"
                    class="mt-2"
                    :rules="[(v) => !!v || 'Wajib diisi']"
                    label="Cara Menggunakan"
                    variant="outlined"
                    required
                  />
                  <v-textarea
                    v-model="form.specification"
                    class="mt-2"
                    :rules="[(v) => !!v || 'Wajib diisi']"
                    label="Spesifikasi"
                    variant="outlined"
                    required
                  />
                  <v-textarea
                    v-model="form.highlight"
                    class="mt-2"
                    :rules="[(v) => !!v || 'Wajib diisi']"
                    label="Sorotan"
                    variant="outlined"
                    required
                  /> -->
                </div>
              </v-card-text>
              <v-card-actions class="justify-end">
                <v-btn color="secondary" @click="handleDialogAdd">
                  Batal
                </v-btn>
                <v-btn color="primary" type="submit"> Tambah Data </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog>
        <!-- edit -->
        <v-dialog v-model="showDialogEdit" width="600">
          <v-card>
            <v-card-title> Ubah Data </v-card-title>
            <v-form v-model="valid" @submit.prevent="editProduct">
              <v-card-text>
                <div class="grid grid-cols-2 gap-4">
                  <v-text-field
                    v-model="form.name"
                    class="mt-2"
                    :rules="[(v) => !!v || 'Wajib diisi']"
                    label="Nama Produk"
                    variant="outlined"
                  />
                  <v-select
                    v-model="form.category"
                    class="mt-2"
                    :rules="[(v) => !!v || 'Wajib diisi']"
                    label="Category"
                    :items="[
                      'Pupuk Hayati',
                      'Pupuk Micro',
                      'Pupuk Macro',
                      'Pupuk Organik',
                    ]"
                    variant="outlined"
                  />
                  <v-select
                    v-model="form.type"
                    class="mt-2"
                    :rules="[(v) => !!v || 'Wajib diisi']"
                    label="Tipe"
                    :items="['Lakaba 5/10', 'Lakaba 8/12/11', 'Lakaba 8/8/8']"
                    variant="outlined"
                    multiple
                  />
                  <v-select
                    v-model="form.plant"
                    class="mt-2"
                    :rules="[(v) => !!v || 'Wajib diisi']"
                    label="Tanaman"
                    :items="['Padi', 'Palawija']"
                    variant="outlined"
                    multiple
                  />
                  <TipTap v-model="form.description" label="Deskripsi" />
                  <TipTap v-model="form.pros" label="Kelebihan" />
                  <TipTap v-model="form.howTo" label="Cara Menggunakan" />
                  <TipTap v-model="form.specification" label="Spesifikasi" />
                  <TipTap v-model="form.highlight" label="Sorotan" />
                  <!-- <v-textarea
                    v-model="form.description"
                    class="mt-2"
                    :rules="[(v) => !!v || 'Wajib diisi']"
                    label="Deskripsi"
                    variant="outlined"
                    required
                  />
                  <v-textarea
                    v-model="form.pros"
                    class="mt-2"
                    :rules="[(v) => !!v || 'Wajib diisi']"
                    label="Kelebihan"
                    variant="outlined"
                    required
                  />
                  <v-textarea
                    v-model="form.howTo"
                    class="mt-2"
                    :rules="[(v) => !!v || 'Wajib diisi']"
                    label="Cara Menggunakan"
                    variant="outlined"
                    required
                  />
                  <v-textarea
                    v-model="form.specification"
                    class="mt-2"
                    :rules="[(v) => !!v || 'Wajib diisi']"
                    label="Spesifikasi"
                    variant="outlined"
                    required
                  />
                  <v-textarea
                    v-model="form.highlight"
                    class="mt-2"
                    :rules="[(v) => !!v || 'Wajib diisi']"
                    label="Sorotan"
                    variant="outlined"
                    required
                  /> -->
                </div>
              </v-card-text>
              <v-card-actions class="justify-end">
                <v-btn color="secondary" @click="handleDialogEdit()">
                  Batal
                </v-btn>
                <v-btn color="primary" type="submit"> Ubah Data </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog>
        <!-- delete -->
        <v-dialog v-model="showDialogDelete" width="600">
          <v-card>
            <v-card-title> Hapus Data </v-card-title>
            <v-card-text>
              <div class="grid grid-cols-6 gap-4">
                <div class="col-span-1">Nama</div>
                <div class="col-span-5 font-bold">{{ form.name }}</div>
                <div class="col-span-1">Kategori</div>
                <div class="col-span-5 font-bold">{{ form.category }}</div>
                <div class="col-span-1">Deskripsi</div>
                <div class="col-span-5 font-bold">{{ form.description }}</div>
                <div class="col-span-1">Tipe</div>
                <div class="col-span-5 font-bold">
                  <div v-for="(detail, index) in form.type" :key="index">
                    {{ detail }}
                  </div>
                </div>
                <div class="col-span-1">Kelebihan</div>
                <div class="col-span-5 font-bold">{{ form.pros }}</div>
                <div class="col-span-1">Cara Menggunakan</div>
                <div class="col-span-5 font-bold">{{ form.howTo }}</div>
                <div class="col-span-1">Spesifikasi</div>
                <div class="col-span-5 font-bold">{{ form.specification }}</div>
                <div class="col-span-1">Tanaman</div>
                <div class="col-span-5 font-bold">
                  <div v-for="(detail, index) in form.plant" :key="index">
                    {{ detail }}
                  </div>
                </div>
                <div class="col-span-1">Sorotan</div>
                <div class="col-span-5 font-bold">{{ form.highlight }}</div>
              </div>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn color="secondary" @click="handleDialogDelete()">
                Batal
              </v-btn>
              <v-btn color="primary" @click="deleteProduct"> Hapus </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- snackbar -->
        <v-snackbar v-model="showSnackbar" location="top right" timeout="2000">
          {{ snackbar }}
          <template v-slot:actions>
            <v-btn color="blue" variant="text" @click="showSnackbar = false">
              Close
            </v-btn>
          </template>
        </v-snackbar>
      </v-main>
    </v-layout>
  </v-card>
</template>

<script setup>
import { ref, onMounted, computed } from "vue";
import { VDataTable } from "vuetify/labs/VDataTable";
import { useStore } from "vuex";
import TipTap from "@/components/TipTap.vue";

const store = useStore();

const snackbar = ref();
const showSnackbar = ref(false);

function loadData() {
  store.commit("setSizeProduct", 10);
  store.dispatch("getListProduct");
}

const headers = ref([
  {
    title: "Foto",
    align: "start",
    sortable: false,
    key: "photos",
  },
  { title: "Nama", key: "name" },
  { title: "Kategori", key: "category" },
  { title: "Tipe", key: "type" },
  { title: "Kelebihan", key: "pros" },
  { title: "Cara Menggunakan", key: "howTo" },
  { title: "Tanaman", key: "plant" },
  { title: "Sorotan", key: "highlight" },
  { title: "Actions", key: "actions", sortable: false },
]);

const valid = ref(false);

const unique = ref(null);

const form = ref({
  photos: ["https://google.photos"],
  category: "",
  name: "",
  description: "",
  type: [],
  pros: "",
  howTo: "",
  specification: "",
  plant: [],
  highlight: "",
});

// view

const showDialogView = ref(false);

function handleDialogView(data) {
  if (showDialogView.value) {
    form.value.photos = ["form.value.https =//google.photos"];
    form.value.category = "";
    form.value.name = "";
    form.value.description = "";
    form.value.type = [];
    form.value.pros = "";
    form.value.howTo = "";
    form.value.specification = "";
    form.value.plant = [];
    form.value.highlight = "";
    unique.value = null;
  } else {
    form.value.category = data.category;
    form.value.name = data.name;
    form.value.description = data.description;
    form.value.type = data.type;
    form.value.pros = data.pros;
    form.value.howTo = data.howTo;
    form.value.specification = data.specification;
    form.value.plant = data.plant;
    form.value.highlight = data.highlight;
    unique.value = data.unique;
  }
  showDialogView.value = !showDialogView.value;
}

// add

const showDialogAdd = ref(false);

function handleDialogAdd() {
  if (showDialogAdd.value) {
    form.value.photos = ["form.value.https =//google.photos"];
    form.value.category = "";
    form.value.name = "";
    form.value.description = "";
    form.value.type = [];
    form.value.pros = "";
    form.value.howTo = "";
    form.value.specification = "";
    form.value.plant = [];
    form.value.highlight = "";
  }
  showDialogAdd.value = !showDialogAdd.value;
}

function addProduct() {
  if (valid.value) {
    store.dispatch("addListProduct", form.value).then((res) => {
      if (res.data.success) {
        loadData();
        handleDialogAdd();
        snackbar.value = "Berhasil ditambahkan";
        showSnackbar.value = true;
      }
    });
  }
}

// edit

const showDialogEdit = ref(false);

function handleDialogEdit(data) {
  if (showDialogEdit.value) {
    form.value.photos = ["form.value.https =//google.photos"];
    form.value.category = "";
    form.value.name = "";
    form.value.description = "";
    form.value.type = [];
    form.value.pros = "";
    form.value.howTo = "";
    form.value.specification = "";
    form.value.plant = [];
    form.value.highlight = "";
    unique.value = null;
  } else {
    form.value.category = data.category;
    form.value.name = data.name;
    form.value.description = data.description;
    form.value.type = data.type;
    form.value.pros = data.pros;
    form.value.howTo = data.howTo;
    form.value.specification = data.specification;
    form.value.plant = data.plant;
    form.value.highlight = data.highlight;
    unique.value = data.unique;
  }
  showDialogEdit.value = !showDialogEdit.value;
}

function editProduct() {
  if (valid.value) {
    store
      .dispatch("editListProduct", {
        ...form.value,
        unique: unique.value,
      })
      .then((res) => {
        if (res.data.success) {
          loadData();
          handleDialogEdit();
          snackbar.value = "Berhasil diubah";
          showSnackbar.value = true;
        }
      });
  }
}

// delete

const showDialogDelete = ref(false);

function handleDialogDelete(data) {
  if (showDialogDelete.value) {
    form.value.photos = ["form.value.https =//google.photos"];
    form.value.category = "";
    form.value.name = "";
    form.value.description = "";
    form.value.type = [];
    form.value.pros = "";
    form.value.howTo = "";
    form.value.specification = "";
    form.value.plant = [];
    form.value.highlight = "";
    unique.value = null;
  } else {
    form.value.category = data.category;
    form.value.name = data.name;
    form.value.description = data.description;
    form.value.type = data.type;
    form.value.pros = data.pros;
    form.value.howTo = data.howTo;
    form.value.specification = data.specification;
    form.value.plant = data.plant;
    form.value.highlight = data.highlight;
    unique.value = data.unique;
  }
  showDialogDelete.value = !showDialogDelete.value;
}

function deleteProduct() {
  store.dispatch("deleteListProduct", unique.value).then((res) => {
    if (res.data.success) {
      loadData();
      handleDialogDelete();
      snackbar.value = "Berhasil dihapus";
      showSnackbar.value = true;
    }
  });
}

const logout = () => {
  localStorage.removeItem("agrofarm");
  window.location.reload();
};

const listProduct = computed(() => {
  return store.getters.getListProduct;
});

onMounted(async () => {
  loadData();
});
</script>