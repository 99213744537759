<template>
  <div class="bg-grey-agro">
    <div
      class="container mx-auto mb-[86px] px-4 sm:px-0 p-20 bg-grey-agro rounded"
    >
      <div class="flex items-center justify-between">
        <div>
          <div class="relative">
            <div
              class="text-[32px] sm:text-[40px] font-extrabold text-black-agro"
            >
              SEE FROM HAPPY FARMERS
            </div>
            <div
              class="hidden sm:block absolute top-[6px] left-[12px] text-[40px] font-extrabold text-black-agro opacity-10"
            >
              SEE FROM HAPPY FARMERS
            </div>
          </div>
          <div class="text-base sm:text-lg text-gray-agro">
            From increased productivity and profitability to sustainable
            practices passion.
          </div>
        </div>
        <div class="hidden sm:block">
          <router-link to="/product">
            <v-btn
              class="btn-header rounded-full"
              variant="outlined"
              append-icon="mdi-arrow-top-right"
            >
              See Products
            </v-btn>
          </router-link>
        </div>
      </div>
      <Carousel
        :autoplay="2000"
        :transition="500"
        :items-to-show="1"
        :wrap-around="true"
        class="block sm:hidden mt-5"
        v-if="listTestimony !== null"
      >
        <Slide v-for="(data, key) in listTestimony" :key="key">
          <div class="carousel__item">
            <card-testimonial
              :image="data.attributes.photos"
              :type="data.attributes.plant"
              :user="data.attributes.name"
              :location="data.attributes.location"
              :description="data.attributes.testimony"
            />
          </div>
        </Slide>
      </Carousel>
      <Carousel
        :autoplay="2000"
        :transition="500"
        :items-to-show="2.5"
        :wrap-around="true"
        class="hidden sm:block mt-[60px]"
        v-if="listTestimony !== null"
      >
        <Slide v-for="(data, key) in listTestimony" :key="key">
          <div class="carousel__item ml-10">
            <card-testimonial
              v-if="data && data.attributes"
              :image="data.attributes.photos"
              :type="data.attributes.plant"
              :user="data.attributes.name"
              :location="data.attributes.location"
              :description="data.attributes.testimony"
            />
          </div>
        </Slide>
      </Carousel>
      <router-link class="block sm:hidden text-center" to="/product">
        <v-btn
          class="btn-header rounded-full"
          variant="outlined"
          append-icon="mdi-arrow-top-right"
        >
          See Products
        </v-btn>
      </router-link>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
import CardTestimonial from "./CardTestimonial.vue";
import { Carousel, Slide } from "vue3-carousel";

export default {
  name: "ListTestimonialLanding",
  components: {
    CardTestimonial,
    Carousel,
    Slide,
  },
  setup() {
    const store = useStore();

    const listTestimony = computed(() => {
      return store.getters.getListTestimony;
    });

    return { listTestimony };
  },
};
</script>