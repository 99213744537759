import axios from 'axios';

export default {
	state: {
		listCareer: [],
		pageInfoCareer: [],
		pageCareer: 1,
		sizeCareer: 10,
		categoryCareer: '',
		detailCareer: [],
	},
	getters: {
		getListCareer: (state) => {
			return state.listCareer;
		},
		getPageInfoCareer: (state) => {
			return state.pageInfoCareer;
		},
		getPageCareer: (state) => {
			return state.pageCareer;
		},
		getSizeCareer: (state) => {
			return state.sizeCareer;
		},
		getCategoryCareer: (state) => {
			return state.categoryCareer;
		},
		getDetailCareer: (state) => {
			return state.detailCareer;
		},
	},
	actions: {
		async getListCareer({ commit }) {
			const params = new URLSearchParams({
				'pagination[page]': 1,
				'pagination[pageSize]': 100,
			});
			const url =
				process.env.VUE_APP_NEW_API_URL +
				`/careers?` +
				params.toString();
			try {
				const response = await axios({
					method: 'get',
					url: url,
				});

				if (response.status === 200) {
					commit('setListCareer', response.data.data);
					commit('setPageInfoCareer', response.data.meta);
				}
				return response;
			} catch (error) {
				return error.response;
			}
		},
		async getDetailCareer({ commit }, data) {
			const url = process.env.VUE_APP_API_URL + `/career/d/${data}`;
			try {
				const response = await axios({
					method: 'get',
					url: url,
				});
				commit('setDetailCareer', response.data.data);
				return response;
			} catch (error) {
				return error.response;
			}
		},
	},
	mutations: {
		setListCareer: (state, data) => {
			state.listCareer = data;
		},
		setDetailCareer: (state, data) => {
			state.detailCareer = data;
		},
	},
};
