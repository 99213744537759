<template>
  <div class="bg-black-agro text-white pt-10 px-4 sm:px-0">
    <div class="container mx-auto">
      <div class="grid sm:grid-cols-2 gap-10">
        <div class="col text-sm">
          <img
            alt="agro-logo-footer"
            :src="require(`@/assets/LogoFooter.svg`)"
          />
          <div class="hidden sm:grid grid-cols-2 my-7">
            <div class="col">
              <div>Head Office:</div>
              <div>Ahmad Dahlan 53, Ponorogo, East Java, Indonesia</div>
            </div>
            <div class="col">
              <div>Factory:</div>
              <div>
                Raya Ponorogo - Madiun KM. 4, Ponorogo, East Java, Indonesia
              </div>
            </div>
          </div>
          <div class="hidden sm:block mb-3">
            <v-icon icon="mdi-phone" /> (0352) 3591094
          </div>
          <div class="hidden sm:block mb-3">
            <v-icon icon="mdi-email-outline" /> bizteam@agrofarm.id
          </div>
        </div>
        <div class="col hidden sm:block">
          <div class="grid grid-cols-1 gap-10">
            <div class="col text-right">
              <div class="grid grid-flow-row gap-2 text-white-agro">
                <div class="pb-2 border-b-2 border-gray-agro text-white">
                  Perusahaan
                </div>
                <div>
                  <router-link to="/about" class="cursor-pointer">Tentang</router-link>
                </div>
                <div>
                  <router-link to="/sustainbility" class="cursor-pointer">Sustainability</router-link>
                </div>
                <div>
                  <router-link to="/testimony" class="cursor-pointer">Testimonial</router-link>
                </div>
                <div>
                  <router-link to="/article" class="cursor-pointer">Artikel</router-link>
                </div>
                <router-link to="/career">
                  <div>Karir</div>
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="block sm:hidden">
          <v-expansion-panels class="bg-transparent" variant="accordion">
            <v-expansion-panel class="text-white">
              <v-expansion-panel-title>Pupuk</v-expansion-panel-title>
              <v-expansion-panel-text class="text-sm">
                Pupuk Hayati
              </v-expansion-panel-text>
              <v-expansion-panel-text class="text-sm">
                Pupuk Micro
              </v-expansion-panel-text>
              <v-expansion-panel-text class="text-sm">
                Pupuk Macro
              </v-expansion-panel-text>
              <v-expansion-panel-text class="text-sm">
                Pupuk Organik
              </v-expansion-panel-text>
            </v-expansion-panel>
            <v-expansion-panel class="text-white">
              <v-expansion-panel-title>Solusi</v-expansion-panel-title>
              <v-expansion-panel-text class="text-sm">
                Ortani
              </v-expansion-panel-text>
              <v-expansion-panel-text class="text-sm">
                Laboratorium
              </v-expansion-panel-text>
              <v-expansion-panel-text class="text-sm">
                Aplikasi Ortani
              </v-expansion-panel-text>
              <v-expansion-panel-text class="text-sm">
                Agronomis
              </v-expansion-panel-text>
            </v-expansion-panel>
            <v-expansion-panel class="text-white">
              <v-expansion-panel-title>Perusahaan</v-expansion-panel-title>
              <v-expansion-panel-text class="text-sm">
                Tentang
              </v-expansion-panel-text>
              <v-expansion-panel-text class="text-sm">
                Sustainability
              </v-expansion-panel-text>
              <v-expansion-panel-text class="text-sm">
                Testimonial
              </v-expansion-panel-text>
              <v-expansion-panel-text class="text-sm">
                <router-link to="/career">
                  <div>Karir</div>
                </router-link>
              </v-expansion-panel-text>
            </v-expansion-panel>
            <v-expansion-panel class="text-white">
              <v-expansion-panel-title
                >Alamat Head office</v-expansion-panel-title
              >
              <v-expansion-panel-text class="text-sm">
                Ahmad Dahlan 53, Ponorogo, East Java, Indonesia
              </v-expansion-panel-text>
            </v-expansion-panel>
            <v-expansion-panel class="text-white">
              <v-expansion-panel-title>Alamat Factory</v-expansion-panel-title>
              <v-expansion-panel-text class="text-sm">
                Raya Ponorogo - Madiun KM. 4, Ponorogo, East Java, Indonesia
              </v-expansion-panel-text>
            </v-expansion-panel>
            <v-expansion-panel class="text-white">
              <v-expansion-panel-title>Hubungi Kami</v-expansion-panel-title>
              <v-expansion-panel-text class="text-sm">
                <div class="mt-3">
                  <v-icon icon="mdi-phone" /> (0352) 3591094
                </div>
                <div class="mt-3">
                  <v-icon icon="mdi-email-outline" /> bizteam@agrofarm.id
                </div>
              </v-expansion-panel-text>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
      </div>
    </div>
    <div class="border-t-2 border-gray-agro mt-10 p-4">
      <div class="container mx-auto block sm:flex items-center justify-between">
        <div
          class="flex justify-center sm:justify-normal sm:grid grid-flow-col gap-4"
        >
          <a href="https://www.linkedin.com/company/pt-agrofarm-nusa-raya/" target="_blank">
            <v-icon icon="mdi-linkedin" />
          </a>
          <a href="https://www.facebook.com/profile.php?id=61552847044762&mibextid=kFxxJD" target="_blank">
            <v-icon icon="mdi-facebook" />
          </a>
          <a href="https://www.instagram.com/agrofarmnusaraya/" target="_blank">
            <v-icon icon="mdi-instagram" />
          </a>
        </div>
        <div class="text-sm mt-2 sm:mt-0 text-center sm:text-left">
          © 2023 PT Agrofarm Nusa Raya. All rights reserved.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FooterLanding",
};
</script>