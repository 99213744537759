<template>
  <v-navigation-drawer
    class="hidden-sm-and-up p-4"
    width="'100%'"
    v-model="sidebar"
    location="top"
    app
  >
    <div class="flex justify-between">
      <v-toolbar-title>
        <router-link to="/" style="cursor: pointer">
          <img alt="agro-logo" :src="require(`@/assets/Logo.svg`)" />
        </router-link>
      </v-toolbar-title>
      <div>
        <v-icon @click="sidebar = !sidebar" icon="mdi-close" />
      </div>
    </div>
    <v-list>
      <router-link
        v-for="(item, index) in menuItems"
        :key="index"
        :to="item.path"
        class="inline-block w-full text-center mt-4"
      >
        <v-btn
          class="btn-header"
          :variant="index === menuItems.length - 1 ? 'outlined' : 'text'"
          :class="
            index === menuItems.length - 1 &&
            'btn-header rounded-full px-4 py-2'
          "
        >
          {{ item.title }}
        </v-btn>
      </router-link>
    </v-list>
  </v-navigation-drawer>
  <v-toolbar class="bg-transparent absolute top-0 w-full" app>
    <v-toolbar-title>
      <router-link to="/" style="cursor: pointer">
        <img alt="agro-logo" :src="require(`@/assets/Logo.svg`)" />
      </router-link>
    </v-toolbar-title>
    <v-spacer />
    <template v-slot:append>
      <v-app-bar-nav-icon
        class="hidden-sm-and-up"
        @click="sidebar = !sidebar"
      />
    </template>
    <v-toolbar-items class="hidden-xs flex items-center">
      <router-link
        v-for="(item, index) in menuItems"
        :key="index"
        :to="item.path"
      >
        <v-btn
          class="btn-header"
          :variant="index === menuItems.length - 1 ? 'outlined' : 'text'"
          :class="
            index === menuItems.length - 1 &&
            'btn-header rounded-full px-4 py-2'
          "
        >
          {{ item.title }}
        </v-btn>
      </router-link>
    </v-toolbar-items>
  </v-toolbar>
</template>

<script>
export default {
  name: "NavbarHome",
  data() {
    return {
      sidebar: false,
      menuItems: [
        { title: "About", path: "/about" },
        { title: "Products", path: "/product" },
        { title: "Sustainability", path: "/sustainbility" },
        { title: "Testimonial", path: "/testimony" },
        { title: "Articles", path: "/article" },
        { title: "Contact Us", path: "/contact" },
      ],
    };
  },
};
</script>