<template>
  <NavbarHome />
  <div class="bg-grey-agro mt-[76px]" v-if="selected">
    <div class="container mx-auto py-4 mt-7">
      <v-breadcrumbs class="text-sm p-0" :items="listBreadcrumb" />
      <ContentSustainbility
        v-model="selected"
        :data="listSustainbility"
        :selected="selected"
        @handleArticle="handleArticle"
      />
    </div>
    <RelatedArticle :data="listArticle" />
    <FooterLanding />
  </div>
</template>

<script>
import { ref, onMounted, computed, watch } from "vue";
import { useStore } from "vuex";
import NavbarHome from "@/layouts/NavbarHome.vue";
import ContentSustainbility from "@/components/Sustainbility/ContentSustainbility.vue";
import RelatedArticle from "@/components/Articles/RelatedArticle/ContentRelatedArticle.vue";
import FooterLanding from "@/components/FooterLanding.vue";

export default {
  name: "SustainbilityView",
  components: {
    NavbarHome,
    ContentSustainbility,
    RelatedArticle,
    FooterLanding,
  },
  setup() {
    const height = ref(0);
    const selected = ref();
    const store = useStore();

    function onScroll() {
      height.value = window.top.scrollY;
      return window.top.scrollY;
    }

    const listSustainbility = computed(() => {
      return store.getters.getListSustainbility;
    });

    const listArticle = computed(() => {
      return store.getters.getListArticle;
    });

    const listBreadcrumb = [
      {
        title: "Homepage",
        disabled: false,
        href: "/",
      },
      {
        title: "Sustainbility",
        disabled: true,
        href: "sustainbility",
      },
    ];

    const handleArticle = (data) => {
      store.commit("setFilterArticle", data);
      store.dispatch("getListArticle");
    };

    watch(
      () => [listArticle.value],
      () => {
        if (listArticle.value.length === 0) {
          store.commit("setFilterArticle", "Berita");
          store.dispatch("getListArticle");
        }
      }
    );

    onMounted(async () => {
      store.dispatch("callListSustainbility").then((res) => {
        selected.value = res[0];
        store.commit("setSizeArticle", 3);
        store.commit("setFilterArticle", res[0].attributes.category || "");
        store.dispatch("getListArticle");
      });
      window.addEventListener("scroll", onScroll);
    });

    return {
      height,
      selected,
      listSustainbility,
      listArticle,
      listBreadcrumb,
      handleArticle,
    };
  },
};
</script>