<template>
  <router-link :to="`/article/${id}`">
    <div class="relative p-3 rounded bg-white shadow">
      <img
        alt="agro-product"
        class="col-span-2 z-10 h-full w-full object-cover object-center"
        :src="image"
      />
      <div class="absolute bottom-6 left-6 right-6 sm:right-24">
        <div class="bg-ascent-500 px-3 rounded text-[11px] w-fit">
          {{ category }}
        </div>
        <div class="block sm:hidden text-lg sm:text-2xl text-white font-bold text-left">
          {{ limitString(title || "", 50) }}
        </div>
        <div class="hidden sm:block text-lg sm:text-2xl text-white font-bold text-left">
          {{ title }}
        </div>
        <div
          class="text-sm text-white-agro font-normal text-left"
          v-html="limitString(description || '', 120)"
        />
      </div>
    </div>
  </router-link>
</template>

<script>
import formats from "@/modules/formats.js";

export default {
  name: "CardResourceBig",
  props: {
    id: {
      type: String,
    },
    image: {
      type: String,
    },
    category: {
      type: String,
    },
    title: {
      type: String,
    },
    description: {
      type: String,
    },
  },
  setup() {
    const { limitString } = formats();

    return { limitString };
  },
};
</script>