<template>
    <div v-for="article in data" :key="article" :class="styles.card_articles">
        <div :class="styles.card_articles__inner">
            <div :class="styles.card_articles__images">
                <img v-if="article.attributes" :src="article.attributes.photos" :alt="article.attributes.title">
            </div>

            <div :class="styles.card_articles__content">
                <badge-ui v-if="article.attributes" :title="article.attributes.category" />
                <a :href="'/article/'+ article.id" v-if="article.attributes" :class="styles.card_articles__title">
                    {{ article.attributes.title }}
                </a>
                <div v-if="article.attributes" :class="styles.card_articles__desc" v-html="article.attributes.news">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import styles from "./CardArticles.module.css";
import classNames from 'classnames';
import BadgeUi from '../badge/BadgeUi.vue';

export default {
    name: "CardArticles",
    components: { BadgeUi },
    props: {
        data: {
            type: [Array, Object],
            required: true
        }
    },
    setup() {
        return {
            styles,
            cn: classNames
        };
    }
};
</script>