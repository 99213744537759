<template>
  <div class="container mx-auto mb-[100px] px-4 sm:px-0">
    <div class="flex items-center justify-between">
      <div>
        <div class="relative">
          <div
            class="text-[32px] sm:text-[40px] font-extrabold text-black-agro"
          >
            LATEST RESOURCES
          </div>
          <div
            class="hidden sm:block absolute top-[6px] left-[12px] text-[40px] font-extrabold text-black-agro opacity-10"
          >
            LATEST RESOURCES
          </div>
        </div>
        <div class="text-base sm:text-lg text-gray-agro">
          Explore a knowledge from farmers experiences, innovative, and
          practical tips.
        </div>
      </div>
      <div class="hidden sm:block">
        <router-link to="/article">
          <v-btn
            class="btn-header rounded-full"
            variant="outlined"
            append-icon="mdi-arrow-top-right"
          >
            See More
          </v-btn>
        </router-link>
      </div>
    </div>
    <div
      class="grid sm:grid-cols-2 gap-y-5 sm:gap-y-0 sm:gap-x-10 items-stretch mt-12"
    >
      <div class="col">
        <card-resource-big
          :id="listArticle[0]?.attributes.id"
          :image="listArticle[0]?.attributes.photos"
          :category="listArticle[0]?.attributes.category"
          :title="listArticle[0]?.attributes.title"
          :description="listArticle[0]?.attributes.news"
        />
      </div>
      <div class="col grid grid-rows-2 gap-y-5">
        <div v-for="(data, key) in listArticle.slice(1)" :key="key">
          <card-resource
            :id="data.attributes.id"
            :image="data.attributes.photos"
            :category="data.attributes.category"
            :title="data.attributes.title"
            :description="data.attributes.news"
            class="col"
          />
        </div>
      </div>
    </div>
    <router-link class="block sm:hidden text-center mt-4" to="/article">
      <v-btn
        class="btn-header rounded-full"
        variant="outlined"
        append-icon="mdi-arrow-top-right"
      >
        See More
      </v-btn>
    </router-link>
  </div>
</template>

<script>
import { computed, onMounted, watch } from "vue";
import { useStore } from "vuex";
import CardResourceBig from "./CardResourceBig.vue";
import CardResource from "./CardResource.vue";

export default {
  components: { CardResourceBig, CardResource },
  name: "ResourceLanding",
  setup() {
    const store = useStore();

    const listArticle = computed(() => {
      return store.getters.getListArticle;
    });

    watch(
        () => [listArticle.value],
        () => {
            if (listArticle.value.length === 0) {
            store.commit("setFilterArticle", "Berita");
            store.dispatch("getListArticle");
            }
        }
    );

    onMounted(async () => {
        store.commit("setSizeArticle", 3);
        store.dispatch("getListArticle");
    });    

    return { listArticle };
  },
};
</script>