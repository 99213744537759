<template>
  <div class="grid grid-cols-1 sm:grid-cols-2 gap-8 mt-7">
    <div
      class="col flex flex-col order-last sm:order-first justify-start"
      :class="readMore ? 'sm:justify-between' : 'sm:justify-start'"
    >
      <div class="text-[24px] md:text-[32px] font-extrabold text-black-agro">
        {{ props.data?.attributes.title }}
      </div>
      <div
        v-html="
          readMore
            ? limitString(props.data?.attributes.description || '', 600)
            : props.data?.attributes.description
        "
        class="text-gray-agro leading-7 text-justify mt-2"
      />
      <div class="text-primary-agro" @click="watchMore" v-if="readMore">
        Lihat Selengkapnya
        <v-icon icon="mdi-arrow-down" />
      </div>
    </div>
    <div class="col order-first sm:order-last rounded-lg">
      <img
        :src="props.data?.attributes.image"
        class="w-full h-[303px] sm:h-[428px] object-contain"
      />
    </div>
  </div>
</template>

<!-- eslint-disable no-undef -->
<script setup>
import { ref } from "vue";
import formats from "@/modules/formats.js";

const { limitString } = formats();
const props = defineProps(["data"]);
const readMore = ref(
  props.data?.attributes.description.length > 600 ? true : false
);

const watchMore = () => {
  readMore.value = !readMore.value;
};
</script>