<template>
  <div class="bg-white p-1 rounded shadow">
    <div class="relative">
      <img
        alt="agro-product"
        class="col-span-2 z-10 w-full h-[200px] object-cover object-center"
        :src="image"
      />
      <div class="absolute bottom-2 left-2">
        <div class="bg-ascent-500 px-3 rounded-t text-[11px] w-fit">
          {{ product }}
        </div>
        <div class="bg-ascent-400 px-3 rounded-b">
          {{ user }}, {{ location }}
        </div>
      </div>
    </div>
    <div class="text-left px-5 py-3">"{{ description }}"</div>
  </div>
</template>

<script>
export default {
  name: "CardTestimonial",
  props: {
    image: {
      type: String,
    },
    product: {
      type: String,
    },
    user: {
      type: String,
    },
    location: {
      type: String,
    },
    description: {
      type: String,
    },
  },
};
</script>