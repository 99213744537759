<template>
  <navbar-home />
  <div class="bg-grey-agro py-4 mt-[76px]">
    <div class="container mx-auto mt-7">
      <v-breadcrumbs
        class="text-sm px-5 py-0 sm:px-0 sm:py-0"
        :items="listBreadcrumb"
      />
      <div
        class="grid grid-cols-1 sm:grid-cols-3 gap-y-7 sm:gap-y-0 sm:gap-16 mt-7 sm:mb-[100px]"
      >
        <div class="col sm:col-span-2 px-5 sm:px-0">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3952.2966319466595!2d111.46914631119816!3d-7.863994478148458!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e799f6ebbfe2601%3A0x4f78d1407d2307a0!2sPT.%20Agrofarm%20Nusa%20Raya%20(HO)!5e0!3m2!1sen!2sid!4v1698270432867!5m2!1sen!2sid"
            height="450"
            style="width: 100%; border: 0"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          />
          <div class="mt-10">
            <div class="font-bold text-black-agro-2">Agrofarm Head Office:</div>
            <div class="font-normal text-gray-agro">
              Ahmad Dahlan 53, Ponorogo, East Java, Indonesia
            </div>
          </div>
          <div class="mt-5">
            <div class="font-bold text-black-agro-2">Agrofarm Factory:</div>
            <div class="font-normal text-gray-agro">
              Raya Ponorogo - Madiun KM. 4, Ponorogo, East Java, Indonesia
            </div>
          </div>
          <div class="mt-10">
            <div class="font-bold text-black-agro-2">Nomor Telpon:</div>
            <div class="font-normal text-gray-agro">(0352) 3591094</div>
          </div>
          <div class="mt-5">
            <div class="font-bold text-black-agro-2">Email:</div>
            <div class="font-normal text-gray-agro">bizteam@agrofarm.id</div>
          </div>
        </div>
        <div
          class="col pt-7 px-5 pb-10 sm:pt-7 sm:px-7 sm:pb-7 sm:rounded-[30px] border-t-2 border-black-agro-3 sm:border-t-0 sm:border-2 sm:bg-white"
        >
          <div class="text-[28px] font-bold">Tanya apapun tentang Agrofarm</div>
          <div class="text-gray-agro">
            Ingin kerjasama ataupun konsultasi pupuk? kirim pesan melalui form
            di bawah ini
          </div>
          <div class="mt-5">
            <label class="text-sm" for="fullname">Nama Lengkap</label>
            <v-text-field name="fullname" variant="outlined" />
          </div>
          <div class="mt-2">
            <label class="text-sm" for="email">Email</label>
            <v-text-field name="email" variant="outlined" />
          </div>
          <div class="mt-2">
            <label class="text-sm" for="phone">Nomor Handphone</label>
            <v-text-field name="phone" variant="outlined" />
          </div>
          <div class="mt-2">
            <label class="text-sm" for="detail">
              Detail informasi yang bisa kami bantu?
            </label>
            <v-text-field name="detail" variant="outlined" />
          </div>
          <v-btn class="mt-7 btn-primary w-full" size="large">
            Kirim Pesan
          </v-btn>
        </div>
      </div>
    </div>
  </div>
  <footer-landing />
</template>

<script setup>
import NavbarHome from "@/layouts/NavbarHome.vue";
import FooterLanding from "@/components/FooterLanding.vue";

const listBreadcrumb = [
  {
    title: "Homepage",
    disabled: false,
    href: "/",
  },
  {
    title: "Hubungi Kami",
    disabled: true,
    href: "contact",
  },
];
</script>