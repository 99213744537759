import axios from 'axios';

export default {
	state: {
		detailAuth: null,
	},
	getters: {
		getDetailAuth: (state) => {
			return state.detailAuth;
		},
	},
	actions: {
		async login({ commit }, data) {
			const url = process.env.VUE_APP_API_URL + '/user/login';
			try {
				const response = await axios({
					method: 'post',
					url: url,
					data,
				});
				if (response.data.success) {
					commit('setLocalstorage', response.data.data);
				}
				return response;
			} catch (error) {
				return error.response;
			}
		},
	},
	mutations: {
		setLocalstorage: (_, data) => {
			window.localStorage.setItem(
				'agrofarm',
				JSON.stringify({
					...data,
					expired: Date.now() + 60 * 60 * 1000,
				}),
			);
		},
	},
};
