<template>
  <div :class="styles.article_detail">
    <div :class="styles.article_detail__inner">
      <breadcrumbs :items="listBreadcrumb" />
      <div class="mt-5" />
      <badge-ui
        v-if="detailArticle.attributes"
        :title="detailArticle.attributes.category"
      />
      <h1 v-if="detailArticle.attributes">
        {{ detailArticle.attributes.title }}
      </h1>
      <img
        v-if="detailArticle.attributes"
        :src="detailArticle.attributes.photos"
        :alt="detailArticle.attributes.title"
      />

      <div :class="styles.article_detail__content">
        <div :class="styles.article_detail__left">
          <div
            :class="styles.article_detail__news"
            v-if="detailArticle.attributes"
            v-html="detailArticle.attributes.news"
          ></div>
        </div>

        <div :class="styles.article_detail__right">
          <div :class="styles.article_detail__shared_box">
            <div :class="styles.top">
              <div :class="styles.top__info">
                <div :class="styles.top__info_label">Ditulis Oleh</div>
                <div
                  v-if="detailArticle.attributes"
                  :class="styles.top__info_value"
                >
                  {{
                    detailArticle.attributes.updatedBy.data.attributes
                      .firstname +
                    " " +
                    detailArticle.attributes.updatedBy.data.attributes.lastname
                  }}
                </div>
              </div>

              <div :class="styles.top__info">
                <div :class="styles.top__info_label">Tanggal Upload</div>
                <div
                  v-if="detailArticle.attributes"
                  :class="styles.top__info_value"
                >
                  {{ getDate(detailArticle.attributes.createdAt) }}
                </div>
              </div>
            </div>

            <div :class="styles.bottom">
              <div :class="styles.bottom__info_label">
                Bagikan Artikel Ini ke:
              </div>
              <div :class="styles.lists__shared">
                <ShareNetwork network="linkedIn" :url="linkArticle">
                  <div
                    :class="cn(styles.lists__shared__item, styles.linkedin)"
                  />
                </ShareNetwork>
                <ShareNetwork network="facebook" :url="linkArticle">
                  <div
                    :class="cn(styles.lists__shared__item, styles.facebook)"
                  />
                </ShareNetwork>
                <ShareNetwork network="twitter" :url="linkArticle">
                  <div
                    :class="cn(styles.lists__shared__item, styles.twitter)"
                  />
                </ShareNetwork>
                <div
                  :class="cn(styles.lists__shared__item, styles.copy)"
                  @click="copyUrl"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <v-snackbar v-model="snackbar.show" :timeout="snackbar.time" location="top right" min-width="0">
    {{ snackbar.text }}
  </v-snackbar>
</template>

<script>
import { ref, computed, onMounted, nextTick } from "vue";
import styles from "./ArticlesDetail.module.css";
import classNames from "classnames";
import breadcrumbs from "@/components/ui/breadcrumb/BreadcrumbUi.vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import BadgeUi from "@/components/ui/badge/BadgeUi.vue";
import moment from "moment";

export default {
  name: "ArticlesDetail",
  components: {
    breadcrumbs,
    BadgeUi,
  },
  setup() {
    const route = useRoute();
    const articleId = route.params.id;
    const store = useStore();
    const listBreadcrumb = ref([
      {
        title: "Dashboard",
        disabled: false,
        href: "/",
      },
      {
        title: "Artikel",
        disabled: false,
        href: "/article",
      },
    ]);
    const snackbar = ref({
      show: false,
      text: "Link berhasil di copy!",
      time: 1000,
    });
    const linkArticle = window.location.href;

    const detailArticle = computed(() => store.getters.getDetailArticle);

    const getDate = (date) => {
      return moment(date).lang("id").format("DD MMM YYYY");
    };

    const copyUrl = () => {
      navigator.clipboard.writeText(linkArticle);
      snackbar.value.show = true;
    };

    onMounted(() => {
      store.dispatch("getDetailArticle", articleId);

      nextTick(() => {
        setTimeout(() => {
          if (detailArticle.value.attributes) {
            listBreadcrumb.value.push({
              title: detailArticle.value.attributes.title,
              disabled: true,
              href: "#",
            });
          }
        }, 500);
      });
    });

    return {
      styles,
      cn: classNames,
      breadcrumbs,
      listBreadcrumb,
      snackbar,
      linkArticle,
      detailArticle,
      getDate,
      copyUrl,
    };
  },
};
</script>