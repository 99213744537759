import { createRouter, createWebHistory, RouterView } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import AboutView from '@/views/AboutView';
import SustainbilityView from '@/views/SustainbilityView';
import ProductView from '@/views/ProductView';
import ProductDetail from '@/views/ProductDetail';
import ContactView from '@/views/ContactView';
import ArticleView from '@/views/ArticleView';
import ArticleDetail from '@/views/ArticleDetail';
import TestimonyView from '@/views/TestimonyView';
// import AgronomisView from '@/views/AgronomisView';
import CareerView from '@/views/CareerView';
// Admin
import LoginView from '@/views/Admin/LoginView.vue';
import TestimonyAdmin from '@/views/Admin/TestimonyAdmin.vue';
import ArticleAdmin from '@/views/Admin/ArticleAdmin.vue';
import ProductAdmin from '@/views/Admin/ProductAdmin.vue';

const token = window.localStorage.getItem('agrofarm');
let expired = false;
if (token !== null) {
	expired = Date.now() > JSON.parse(token).expired;
} else {
	expired = true;
}
if (expired) {
	localStorage.removeItem('agrofarm');
}

const routes = [
	{
		path: '/',
		name: 'home',
		component: HomeView,
	},
	{
		path: '/about',
		name: 'about',
		component: AboutView,
	},
	{
		path: '/product',
		name: 'product',
		children: [
			{
				path: '',
				name: 'productList',
				component: ProductView,
			},
			{
				path: ':id',
				name: 'productDetail',
				component: ProductDetail,
			},
		],
	},
	{
		path: '/contact',
		name: 'contact',
		component: ContactView,
	},
	{
		path: '/sustainbility',
		name: 'sustainbility',
		component: SustainbilityView,
	},
	{
		path: '/article',
		name: 'article',
		children: [
			{
				path: '',
				name: 'articleList',
				component: ArticleView,
			},
			{
				path: ':id',
				name: 'articleDetail',
				component: ArticleDetail,
			},
		],
	},
	{
		path: '/testimony',
		name: 'testimony',
		component: TestimonyView,
	},
	// {
	// 	path: '/agronomis',
	// 	name: 'agronomis',
	// 	component: AgronomisView,
	// },
	{
		path: '/career',
		name: 'career',
		component: CareerView,
	},
	{
		path: '/admin',
		name: 'admin',
		component: RouterView,
		children: [
			{
				path: 'login',
				name: 'adminLogin',
				component: LoginView,
				beforeEnter(to, from, next) {
					if (!expired) {
						next('/admin');
					} else {
						next();
					}
				},
			},
			{
				path: '',
				name: 'testimonyAdmin',
				component: TestimonyAdmin,
				beforeEnter(to, from, next) {
					if (!expired) {
						next();
					} else {
						next('/admin/login');
					}
				},
			},
			{
				path: 'article',
				name: 'articleAdmin',
				component: ArticleAdmin,
				beforeEnter(to, from, next) {
					if (!expired) {
						next();
					} else {
						next('/admin/login');
					}
				},
			},
			{
				path: 'product',
				name: 'productAdmin',
				component: ProductAdmin,
				beforeEnter(to, from, next) {
					if (!expired) {
						next();
					} else {
						next('/admin/login');
					}
				},
			},
		],
	},
];

const router = createRouter({
	mode: 'history',
	history: createWebHistory(process.env.BASE_URL),
	routes,
});

export default router;
