<template>
  <div :class="styles.spacer"></div>
  <div :class="styles.product_list__container">
    <div :class="styles.product_list__inner">
      <div :class="styles.product_list__banner">
        <div :class="styles.product_list__banner_inner">
          <p :class="styles.product_list__banner_text">
            Lihat berbagai produk pupuk dalam satu tempat, download brosur untuk
            bisa melihat semua pupuk sekaligus.
            <a
              href="#"
              @click="fetchBrosur()"
              :class="styles.product_list__banner_text__mobile"
              >Download Brosur</a
            >
            <a
              href="#"
              @click="fetchBrosur()"
              :class="
                cn(
                  generalStyles.btn_primary,
                  styles.product_list__banner_text__desktop
                )
              "
              >Download Brosur</a
            >
          </p>
        </div>
      </div>

      <div :class="styles.product_list__content">
        <div :class="styles.product_list__content_wrapper">
          <div :class="styles.product_list__top">
            <breadcrumbs :items="listBreadcrumb" />

            <div :class="styles.product_list__top_category">
              <div :class="styles.title">
                Tampilkan pupuk berdasarkan kategori:
              </div>
              <div :class="styles.list_category">
                <div
                  v-for="category in categoryList"
                  :class="[
                    styles.list_category__item,
                    { [styles.active]: activeCategory === category },
                  ]"
                  :key="category"
                  @click="handleCategory(category)"
                >
                  {{ category }}
                </div>
              </div>
            </div>
          </div>

          <div :class="styles.product_list__bottom">
            <div :class="styles.product_list__info_text">
              <p>
                Menampilkan {{ listProduct.length }} pupuk berdasarkan
                <br class="block" />
                <span>
                  {{ activeCategory }}
                  <div
                    v-if="activeCategory !== 'Semua Pupuk'"
                    :class="styles.clear"
                    @click="clearCategory()"
                  />
                </span>
              </p>
            </div>
            <div :class="styles.product_list__info_list">
              <product-list-ui
                v-for="(data, key) in listProduct"
                :key="key"
                :imageSrc="data.attributes.photos[0]"
                :label="data.attributes.category"
                :title="data.attributes.name"
                :description="data.attributes.description"
                :link="`/product/${data.id}`"
                :comingSoon="!data.attributes.coming_soon ? false : true"
              />
            </div>
          </div>

          <div class="mt-8">
            <v-pagination
              v-model="activePage"
              active-color="#108869"
              :length="paginationProduct.pageCount"
              @update:modelValue="handlePage"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed } from "vue";
import styles from "./ProductList.module.css";
import breadcrumbs from "@/components/ui/breadcrumb/BreadcrumbUi.vue";
import { useStore } from "vuex";
import ProductListUi from "@/components/ui/product/list/ProductList.ui.vue";
import generalStyles from "../../../../GeneralStyles.module.css";
import classNames from "classnames";
import { useRouter } from "vue-router";

export default {
  name: "ProductView",
  components: { breadcrumbs, ProductListUi },
  setup() {
    const store = useStore();
    const router = useRouter();
    const query = router.currentRoute.value.query;

    const activePage = computed(() => {
      return store.getters.getPageProduct;
    });

    const categoryProduct = computed(() => {
      return store.getters.getCategoryProduct;
    });

    
    let listBreadcrumb = ref([
      {
        title: "Homepage",
        disabled: false,
        href: "/",
      },
    ]);

    const categoryList = [
        "Semua Pupuk", 
        "Pupuk Hayati", 
        "Pupuk Macro", 
        "Pupuk Micro", 
        "Pupuk Organik", 
        "Pupuk Macro Micro Campuran", 
        "Senyawa Humat"
    ];

    const activeCategory = ref(categoryList[0]);

    const listProduct = computed(() => {
      return store.getters.getListProduct;
    });

    const paginationProduct = computed(() => {
      return store.getters.getPageInfoProduct;
    });

    const fetchBrosur = async () => {
      const res = await store.dispatch("getBrosur");
      if (res.data.data.attributes) {
        window.open(res.data.data.attributes.url, "_blank").focus();
      }
    };

    const handleCategory = (category) => {
      activeCategory.value = category;
      store.commit("setPageProduct", 1);
      if (category == "Semua Pupuk") {
        store.commit("setCategoryProduct", "");
      } else {
        store.commit("setCategoryProduct", category);
      }
      store.dispatch("getListProduct");

      listBreadcrumb.value[1] = {
        title: category,
        disabled: true,
        href: "product",
      };
    };

    const clearCategory = () => {
      activeCategory.value = "Semua Pupuk";
      store.commit("setCategoryProduct", "");
      store.dispatch("getListProduct");
    };

    const handlePage = (page) => {
      store.commit("setPageProduct", page);
      store.dispatch("getListProduct");
    };

    onMounted(async () => {
      store.commit("setSizeProduct", 12);
      store.dispatch("getListProduct");

      store.dispatch("getBrosur");

      listBreadcrumb.value.push({
        title: "Semua Pupuk",
        disabled: true,
        href: "product",
      });

      setTimeout(() => {
        if (Object.keys(query).length > 0) handleCategory(query.tab);
      }, 1000);
    });

    return {
      styles,
      listBreadcrumb,
      activePage,
      categoryList,
      activeCategory,
      handleCategory,
      clearCategory,
      categoryProduct,
      listProduct,
      paginationProduct,
      fetchBrosur,
      handlePage,
      generalStyles,
      cn: classNames,
    };
  },
};
</script>
