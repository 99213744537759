<template>
  <navbar-home />
  <div class="bg-grey-agro py-4 mt-[20px] lg:mt-[76px]">
    <div class="container px-6 mx-auto mt-7">
      <v-breadcrumbs class="text-sm p-0" :items="listBreadcrumb" />
      <div class="mt-7 text-2xl lg:text-[40px] font-bold">
        Cerita Petani Tentang Agrofarm
      </div>
      <div class="mt-1 text-base lg:text-lg font-normal">
        Testimoni petani tentang peningkatan produktivitas dan profitabilitas
      </div>
      <div
        class="grid grid-cols-1 lg:grid-cols-3 gap-10 mt-11 lg:mt-12 mb-16 lg:mb-[100px]"
      >
        <div v-for="(data, key) in listTestimony" :key="key" class="col">
          <card-testimonial
            :image="data.attributes.photos"
            :type="data.attributes.plant"
            :user="data.attributes.name"
            :location="data.attributes.location"
            :description="description(data.attributes.testimony)"
          />
        </div>
      </div>
    </div>
  </div>
  <footer-landing />
</template>

<script>
import { onMounted, computed } from "vue";
import { useStore } from "vuex";
import NavbarHome from "@/layouts/NavbarHome.vue";
import CardTestimonial from "@/components/Testimonial/CardTestimonial.vue";
import FooterLanding from "@/components/FooterLanding.vue";

export default {
  name: "TestimonyView",
  components: { NavbarHome, CardTestimonial, FooterLanding },
  setup() {
    const store = useStore();

    const listTestimony = computed(() => {
      return store.getters.getListTestimony;
    });

    const listBreadcrumb = [
      {
        title: "Homepage",
        disabled: false,
        href: "/",
      },
      {
        title: "Testimonial",
        disabled: true,
        href: "testimony",
      },
    ];

    function description(data) {
      return data.substring(0, 150) + "...";
    }

    onMounted(async () => {
      store.commit("setSizeTestimony", 9);
      store.dispatch("getListTestimony");
    });

    return { listTestimony, listBreadcrumb, description };
  },
};
</script>