<template>
  <div class="mt-5 bg-white border-white p-5 sm:p-10 border-2 rounded-[40px]">
    <div
      class="flex items-center gap-4 bg-grey-agro px-2 py-2 sm:px-4 sm:py-4 rounded-full whitespace-nowrap w-full overflow-x-scroll hidden-scroll"
    >
      <div class="pr-2 border-r">
        <v-icon
          icon="mdi-arrow-left-thin-circle-outline"
          size="40"
          :color="widthScroll === 0 ? '#10886950' : '#108869'"
          @click="scrollLeft"
        />
      </div>
      <div
        id="listMenu"
        class="flex items-center gap-4 bg-grey-agro px-2 py-2 sm:px-4 sm:py-4 whitespace-nowrap w-full overflow-x-scroll hidden-scroll"
      >
        <v-btn
          class="rounded-full max-w-none"
          :class="item.id === props.selected?.id && 'text-primary-agro'"
          v-for="(item, key) in props.data"
          :key="key"
          @click="onChangeSelected(item)"
        >
          {{ item.attributes.title }}
        </v-btn>
      </div>
      <div class="pl-2 border-l">
        <v-icon
          icon="mdi-arrow-right-thin-circle-outline"
          size="40"
          :color="widthScroll >= totalWidth ? '#10886950' : '#108869'"
          @click="scrollRight"
        />
      </div>
    </div>
    <DetailSustainbility :data="props.selected" />
  </div>
</template>

<!-- eslint-disable no-undef -->
<script setup>
import { ref, onMounted } from "vue";
import DetailSustainbility from "@/components/Sustainbility/DetailSustainbility.vue";

const emits = defineEmits(["update:modelValue", "handleArticle"]);
const props = defineProps(["data", "selected"]);
const widthScroll = ref(0);
const totalWidth = ref(0)

const onChangeSelected = (data) => {
  emits("update:modelValue", data);
  emits("handleArticle", data.attributes.category);
};

const scrollLeft = () => {
  let content = document.getElementById("listMenu");
  content.scrollLeft -= 100;
  widthScroll.value = content.scrollLeft
};

const scrollRight = () => {
  let content = document.getElementById("listMenu");
  content.scrollLeft += 100;
  widthScroll.value = content.scrollLeft
};

onMounted(() => {
  let content = document.getElementById("listMenu");
  totalWidth.value = content.offsetWidth + 158
})
</script>
