<template>
  <div class="rounded-t-[80px] bg-grey-agro py-[60px] px-4 sm:px-0">
    <div class="container mx-auto">
      <div class="text-xl">Trusted by 40+ farmer company:</div>
      <div class="mt-10">
        <div class="slider-client">
          <div class="slide-track">
            <div class="slide">
              <img alt="agro-product" :src="require(`@/assets/Sinarmas.svg`)" />
            </div>
            <div class="slide">
              <img alt="agro-product" :src="require(`@/assets/Wilmar.svg`)" />
            </div>
            <div class="slide">
              <img
                alt="agro-product"
                :src="require(`@/assets/Plantation.svg`)"
              />
            </div>
            <div class="slide">
              <img
                alt="agro-product"
                :src="require(`@/assets/CitraBorneoIndah.svg`)"
              />
            </div>
            <div class="slide">
              <img alt="agro-product" :src="require(`@/assets/HPIAgro.svg`)" />
            </div>
            <div class="slide">
              <img alt="agro-product" :src="require(`@/assets/KLK.svg`)" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ClientLanding",
};
</script>