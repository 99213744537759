<template>
  <navbar-home />
  <div class="mt-[76px]"></div>
  <articles-detail />
  <footer-landing />
</template>

<script>
import NavbarHome from "@/layouts/NavbarHome.vue";
import FooterLanding from "@/components/FooterLanding.vue";
import ArticlesDetail from '@/components/pages/articles/detail/ArticlesDetail.vue';

export default {
  name: "ArticleDetail",
  components: {
    NavbarHome,
    FooterLanding,
    ArticlesDetail
  },
  setup() {
    return {};
  }
};
</script>