<template>
    <div :class="styles.bigCardArticle" v-if="data.length > 0">
        <div :class="styles.bigCardArticle__inner">
            <div :class="styles.bigCardArticle__images" :style="{
        backgroundImage: data[0] && data[0].attributes && data[0].attributes.photos ? `url(${data[0].attributes.photos})` : ''
    }
        ">
                <a v-if="data[0] && data[0].attributes" :href="'/article/' + data[0].id"
                    :class="styles.bigCardArticle__images_caption">
                    <div class="px-3">
                        <badge-ui v-if="data[0] && data[0].attributes" :title="data[0].attributes.category" />
                    </div>
                    <div :class="styles.title" v-if="data[0] && data[0].attributes">{{ data[0].attributes.title }}</div>
                    <div :class="styles.desc" v-if="data[0] && data[0].attributes">{{ data[0].attributes.news }}</div>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import styles from "./BigCardArticle.module.css";
import classNames from 'classnames';
import BadgeUi from '../../badge/BadgeUi.vue';

export default defineComponent({
    name: "BigCardArticle",
    components: { BadgeUi },
    props: {
        data: {
            type: Array,
            required: true
        }
    },
    setup(props) {
        if (props.data.length > 0 && props.data[0].attributes) {
            console.log(props.data[0].attributes);
        } else {
            console.log('Data is undefined or empty');
        }

        return {
            styles,
            cn: classNames,
            BadgeUi
        };
    }
});
</script>
