<template>
  <navbar-home />
  <product-list />
  <footer-landing />
</template>

<script>
import NavbarHome from "@/layouts/NavbarHome.vue";
import ProductList from '@/components/pages/product/list/ProductList.vue';
import FooterLanding from "@/components/FooterLanding.vue";

export default {
    name: "ProductView",
    components: { NavbarHome, ProductList, FooterLanding },
    setup() {
        return {};
    }
};
</script>