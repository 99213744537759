<template>
    <div :class="cn(styles.badge, styles.badge__primary)">{{ title }}</div>
</template>

<script>
import styles from "./BadgeUi.module.css";
import classNames from 'classnames';

export default {
    name: "BadgeUi",
    props: {
        title: {
            type: String,
            required: true
        }
    },
    setup() {
        return {
            styles,
            cn: classNames
        };
    }
};
</script>