<template>
    <div :class="styles.carousel__container">
        <div :class="styles.carousel__inner">
            <swiper 
            :autoplay="{
                delay: 3000,
                disableOnInteraction: false,
            }"
            :modules="modules"
            :class="styles.swiper">
                <swiper-slide v-for="(image, index) in images" :key="index"
                :class="styles.swiper__slider"
                >
                    <!-- DESKTOP -->
                    <img
                        :src="image.desktop"
                        class="hidden sm:block md:max-h-[800px] object-cover w-full relative"
                        alt="..."
                    />

                    <!-- MOBILE -->
                    <img
                        :src="image.mobile"
                        class="block sm:hidden max-h-[568px] object-cover w-full relative"
                        alt="..."
                    />
                </swiper-slide>
            </swiper>

            <div :class="styles.carousel__overlay_text">
                <div :class="styles.carousel__overlay_text_inner">
                    <h1>Menjamin Masa Depan Industri Pertanian Indonesia</h1>
                    <p>Menerapkan Sistem Manajemen Mutu ISO SNI 9001:2015 dan standart produk SNI 2803.2012 untuk kualitas produk dan inovasi agri industri Indonesia</p>
                    <div :class="styles.carousel__button_wrapper">
                        <a :class="cn(generalStyles.btn_primary, styles.btn_primary)" href="/product">Lihat Produk</a>
                        <a :class="cn(generalStyles.btn_secondary, styles.btn_secondary)" href="/contact">Hubungi Kami
                            <div :class="styles.btn_primary__icon" />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import styles from "./CarouselLanding.module.css";
import generalStyles from "../../../../GeneralStyles.module.css";
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/autoplay';


import classNames from 'classnames';

import { onMounted } from "vue";
import { initFlowbite } from "flowbite";

export default {
    name: "CarouselLanding",
    components: {
        Swiper,
        SwiperSlide
    },
    setup() {
        onMounted(() => {
            initFlowbite();
        });

        const images = [
            {
                desktop: "https://mimin.agrofarmnusaraya.id/uploads/Background_ae33c8e9d5.svg",
                mobile: require('@/assets/images/mobile/hero-1.jpg'),
            },
            {
                desktop: "https://mimin.agrofarmnusaraya.id/uploads/Backgroundlab_1_1_d36c522f9f.png",
                mobile: require('@/assets/images/mobile/hero-2.jpg'),
            },
            {
                desktop: "https://mimin.agrofarmnusaraya.id/uploads/Backgroundpabrik_1_1_c8ca4f72b8.png",
                mobile: require('@/assets/images/mobile/hero-3.jpg'),
            },
            {
                desktop: "https://mimin.agrofarmnusaraya.id/uploads/Backgroundpabrik_2_1_069310cbf3.png",
                mobile: require('@/assets/images/mobile/hero-4.jpg'),
            },
        ];

        return {
            styles,
            generalStyles,
            cn: classNames,
            images,
            modules: [Autoplay],
        };
    }
};
</script>