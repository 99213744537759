<template>
  <div :class="styles.product_detail__container" v-if="detailProduct">
    <div :class="styles.product_detail__inner">
      <div :class="styles.product_detail__preview">
        <breadcrumbs
          :items="listBreadcrumb"
          :class="styles.breadcrumbs__mobile"
        />
        <h1 v-if="detailProduct.attributes">
          {{ detailProduct.attributes.type[activeIndex] }}
        </h1>

        <div :class="styles.preview__left">
          <breadcrumbs
            :items="listBreadcrumb"
            :class="styles.breadcrumbs__desktop"
          />
          <div class="mb-5"></div>
          <div :class="styles.product_detail__preview_wrapper">
            <swiper
              ref="mySwiper"
              v-if="variants.length > 0"
              :slidesPerView="1"
              :spaceBetween="30"
              :loop="true"
              :pagination="{
                clickable: true,
              }"
              :navigation="true"
              :modules="[Pagination, Navigation]"
              @slideChange="onSlideChange"
            >
              <swiper-slide :key="key" v-for="(item, key) in variants">
                <div :class="styles.product_detail__preview_image_wrapper">
                  <img
                    :class="styles.product_detail__preview_image"
                    :src="item.attributes.photos"
                  />
                </div>
              </swiper-slide>
            </swiper>
          </div>

          <div :class="styles.product_detail__jenis_pupuk">
            <div :class="styles.title">Jenis Pupuk</div>
            <div
              v-if="variants.length > 0"
              :class="styles.product_detail__jenis_pupuk_lists"
            >
              <div
                :key="key"
                v-for="(item, key) in variants"
                :class="[
                  styles.product_detail__jenis_pupuk_lists_item,
                  { [styles.active]: key === activeIndex },
                ]"
                @click="goToSlide(key)"
              >
                <div :class="styles.wrapper">
                  <img :src="item.attributes.photos" :class="styles.image" />
                  <div :class="styles.label">{{ item.attributes.type }}</div>
                </div>
              </div>
            </div>
          </div>

          <div
            ref="descriptionAttr"
            :class="[
              cn(styles.product_detail__desc, styles.mobile),
              { [styles.expanded]: isDescActive },
            ]"
            v-if="variants.length > 0"
            v-html="variants[activeIndex].attributes.description"
          />

          <div
            ref="btnMoreDesc"
            :class="[
              cn(styles.product_detail__desc_more, styles.mobile),
              { [styles.expanded]: isDescActive },
            ]"
            @click="toggleDescActive()"
          >
            <span>{{
              isDescActive ? "Lihat Lebih Sedikit" : "Lihat Selengkapnya"
            }}</span>
            <div :class="styles.icons" />
          </div>

          <div :class="cn(styles.product_detail__tabbing, styles.mobile)">
            <div
              :class="cn(styles.product_detail__tabbing_header, styles.mobile)"
            >
              <div
                :class="[
                  cn(styles.product_detail__tabbing_header_item, styles.mobile),
                  { [styles.active]: key === activeTab },
                ]"
                v-for="(item, key) in tabbing"
                :key="key"
                @click="setActiveTab(key)"
              >
                {{ item.name }}
              </div>
            </div>

            <div
              :class="styles.product_detail__tabbing_body"
              v-if="variants.length > 0"
              v-html="
                variants[activeIndex].attributes[tabbing[activeTab].content]
              "
            ></div>
            <div v-else>
              <p>-</p>
            </div>
          </div>
        </div>

        <div :class="styles.preview__right">
          <div class="mb-7"></div>
          <div :class="styles.product_detail__title" v-if="variants.length > 0">
            {{ variants[activeIndex].attributes.name }}
          </div>

          <div
            ref="descriptionAttr"
            :class="[
              cn(styles.product_detail__desc, styles.desktop),
              { [styles.expanded]: isDescActive },
            ]"
            v-if="variants.length > 0"
            v-html="variants[activeIndex].attributes.description"
          />

          <div
            ref="btnMoreDesc"
            :class="[
              cn(styles.product_detail__desc_more, styles.desktop),
              { [styles.expanded]: isDescActive },
            ]"
            @click="toggleDescActive()"
          >
            <span>{{
              isDescActive ? "Lihat Lebih Sedikit" : "Lihat Selengkapnya"
            }}</span>
            <div :class="styles.icons" />
          </div>

          <div :class="cn(styles.product_detail__tabbing, styles.desktop)">
            <div
              :class="cn(styles.product_detail__tabbing_header, styles.desktop)"
            >
              <div
                :class="[
                  cn(
                    styles.product_detail__tabbing_header_item,
                    styles.desktop
                  ),
                  { [styles.active]: key === activeTab },
                ]"
                v-for="(item, key) in tabbing"
                :key="key"
                @click="setActiveTab(key)"
              >
                {{ item.name }}
              </div>
            </div>

            <div
              :class="styles.product_detail__tabbing_body"
              v-if="variants.length > 0"
              v-html="
                variants[activeIndex].attributes[tabbing[activeTab].content]
              "
            ></div>
            <div v-else>
              <p>-</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <another-product-vue></another-product-vue>

  <div :class="styles.product_detail__buttons_flying">
    <div :class="styles.product_detail__buttons_flying_inner">
      <div
        @click="fetchBrosur()"
        :class="cn(generalStyles.btn_secondary, styles.btn_secondary)"
      >
        Lihat Brosur
      </div>
      <div
        @click="openModal()"
        :class="cn(generalStyles.btn_primary, styles.btn_primary)"
      >
        Beli Pupuk
      </div>
    </div>
  </div>

  <!-- MODAL BELI PUPUK -->
  <div v-if="isModalOpen" class="modal" @click.self="closeModal">
    <!-- Modal content -->
    <div class="modal-content">
      <div class="modal-content__head">
        <span class="close" @click="closeModal"></span>
      </div>
      <div class="modal-content__body">
        <div :class="styles.list__buy_title">Beli pupuk ini melalui</div>
        <div
          :class="styles.list__buy"
          v-for="(item, key) in buyList"
          :key="key"
          @click="buyPupuk(item)"
        >
          <div :class="cn(styles.list__buy_icon, styles[item.icon])" />
          <div :class="styles.list__buy_text">{{ item.name }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { computed, onMounted, ref, nextTick } from "vue";
import { useRoute } from "vue-router";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation } from "swiper/modules";
import styles from "@/components/pages/product/detail/ProductDetail.module.css";
import generalStyles from "../../../../GeneralStyles.module.css";
import classNames from "classnames";
import breadcrumbs from "@/components/ui/breadcrumb/BreadcrumbUi.vue";
import AnotherProductVue from "./AnotherProduct.vue";

export default {
  name: "ProductDetail",
  components: { Swiper, SwiperSlide, breadcrumbs, AnotherProductVue },
  setup() {
    const route = useRoute();
    const productId = route.params.id;
    const store = useStore();
    const detailProduct = computed(() => store.getters.getDetailProduct);
    const activeIndex = ref(0);
    const mySwiper = ref();
    const isDescActive = ref(false);
    const descriptionAttr = ref(null);
    const btnMoreDesc = ref(null);
    const activeTab = ref(0);
    const variants = ref([]);
    const isModalOpen = ref(false);

    let listBreadcrumb = ref([
      {
        title: "Homepage",
        disabled: false,
        href: "/",
      },
    ]);

    const buyList = [
      {
        id: 1,
        name: "Telpon Agrofarm",
        icon: "phone",
        link: "tel:03523591094",
      },
      {
        id: 2,
        name: "Tokopedia",
        icon: "tokopedia",
        link: "https://tokopedia.link/z1l2xUsPaLb",
      },
      {
        id: 3,
        name: "Shopee",
        icon: "shopee",
        link: "https://id.shp.ee/XT5Byj5",
      },
    ];

    const fetchBrosur = async () => {
      const res = await store.dispatch("getBrosur");
      if (res.data.data.attributes) {
        window.open(res.data.data.attributes.url, "_blank").focus();
      }
    };

    onMounted(async () => {
      await store.dispatch("getDetailProduct", productId);

      setTimeout(() => {
        if (descriptionAttr.value) {
          const rect = descriptionAttr.value.getBoundingClientRect();
          if (rect.height < 80) btnMoreDesc.value.style.display = "none";
        }
      }, 1000);

      nextTick(() => {
        if (detailProduct.value.attributes) {
          const categories = detailProduct.value.attributes.category;

          listBreadcrumb.value.push({
            title: detailProduct.value.attributes.category,
            disabled: true,
            href: `/product?tab=${categories}`,
          });

          listBreadcrumb.value.push({
            title: detailProduct.value.attributes.name,
            disabled: true,
            href: `/product/${productId}`,
          });

          variants.value = detailProduct.value.attributes.variants.data;
        }
      });
    });

    const onSlideChange = (swiper) => {
      activeIndex.value = swiper.realIndex;
    };

    const goToSlide = (index) => {
      mySwiper.value.$el.swiper.slideTo(index);
      activeIndex.value = index;
    };

    const toggleDescActive = () => {
      isDescActive.value = !isDescActive.value;
      if (descriptionAttr.value) {
        const descriptionAttrs = document.querySelector(
          `.${descriptionAttr.value.classList[0]}`
        );
        descriptionAttrs.style.height = "20px !important";
      }
    };

    const tabbing = [
      { id: 1, name: "Keunggulan", content: "pros" },
      { id: 2, name: "Cara Penggunaan", content: "how_to" },
      { id: 3, name: "Spesifikasi", content: "specification" },
      { id: 4, name: "Tanaman", content: "plant" },
    ];

    const setActiveTab = (key) => {
      activeTab.value = key;
    };

    const openModal = () => {
      isModalOpen.value = true;
    };

    const closeModal = () => {
      isModalOpen.value = false;
    };

    const buyPupuk = (item) => {
      window.open(item.link, "_blank").focus();
    };

    return {
      activeTab,
      styles,
      detailProduct,
      activeIndex,
      Pagination,
      Navigation,
      onSlideChange,
      goToSlide,
      mySwiper,
      generalStyles,
      cn: classNames,
      toggleDescActive,
      tabbing,
      setActiveTab,
      listBreadcrumb,
      variants,
      fetchBrosur,
      isModalOpen,
      openModal,
      closeModal,
      buyList,
      buyPupuk,
      descriptionAttr,
      isDescActive,
      btnMoreDesc,
    };
  },
};
</script>

<style>
.swiper-pagination-bullet {
  background: #04040440;
}

.swiper-pagination-bullet-active {
  background: #108869;
  width: 10px;
  height: 10px;
}

.swiper-button-prev,
.swiper-button-next {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #04040480;
}

.swiper-button-prev::after,
.swiper-button-next::after {
  content: "";
  background-image: url("@/assets/icons/arrow-right-white.svg");
  display: block;
  width: 100%;
  height: 100%;
  background-size: 50% 50%;
  background-position: center;
  background-repeat: no-repeat;
}

.swiper-button-prev::after {
  transform: rotate(180deg);
}

.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: #040404bf;
}

.modal-content {
  width: fit-content;
  transition: transform 0.3s ease;
}

.modal-content__head {
  width: 100%;
  display: flex;
  justify-content: end;
}

.modal-content__head span {
  width: 28px;
  height: 28px;
  background-image: url("@/assets/icons/close-white.svg");
}

.modal-content__body {
  background: #e8e8e8;
  border: 1px solid #e8e8e8;
  border-radius: 20px;
  padding: 40px 80px;
  margin-top: 20px;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  opacity: 1;
  transition: opacity 0.3s;
}

.modal-fade-enter,
.modal-fade-leave-to {
  opacity: 0;
}

.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}
</style>
